import React from "react";
import "./NotFound.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div className="not-found">
      <Helmet>
        <title>404 | {t("site-title")}</title>
      </Helmet>
      <h1>{t("404-title")}</h1>
      <p>{t("404-desc")}</p>
    </div>
  );
}

export default PageNotFound;
