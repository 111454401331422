export class LaCmokEvents {
  static get ON_FILM_READY() {
    return "onFilmReady";
  }

  static get ON_USER_READY() {
    return "onUsersReady";
  }

  static get ON_CATALOG_READY() {
    return "onCatalogReady";
  }

  static get ON_GENERS_READY() {
    return "onGenersReady";
  }

  static get ON_AUTH_CHANGED() {
    return "onAuthStateChanged";
  }

  static get LOAD_CATALOG() {
    return "LOAD_CATALOG";
  }
}
