import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import transBE from "../locale/BY_be.json";
import transRU from "../locale/RY_ru.json";

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: "be",
    fallbackLng: "be",
    supportedLngs: ["be", "ru"],
    keySeparator: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      be: { translations: transBE },
      ru: { translations: transRU },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
