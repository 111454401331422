import { LaCmokEvents } from "./LaCmokEvents";

export class LaCmokEventDispacher {
  /**
   * List of valid events
   */
  EVENT_NAMES = [
    LaCmokEvents.ON_AUTH_CHANGED,
    LaCmokEvents.ON_CATALOG_READY,
    LaCmokEvents.ON_FILM_READY,
    LaCmokEvents.ON_USER_READY,
    LaCmokEvents.ON_GENERS_READY,
    LaCmokEvents.LOAD_CATALOG,
  ];

  eventHandlers = {};

  constructor() {
    this.eventHandlers = {};
  }

  isValidEventName(eventName) {
    for (let i = 0; i < this.EVENT_NAMES.length; i++) {
      if (eventName === this.EVENT_NAMES[i]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if event dispatcher already has some event, by it's id.
   *
   * @param eventId
   * @param eventHandler
   * @returns {boolean}
   */
  hasEventHandler(/* String */ eventId, /* Function */ eventHandler) {
    if (!(eventId in this.eventHandlers)) {
      return false;
    }
    const handlers = this.eventHandlers[eventId];
    const n = handlers.length;
    for (let i = 0; i < n; i++) {
      if (handlers[i] === eventHandler) {
        return true;
      }
    }
    return false;
  }

  addEventHandler(/* String */ eventId, /* Function */ eventHandler) {
    if (!this.isValidEventName(eventId)) {
      throw "invalid event id for LaCmok " + eventId;
    }
    if (this.hasEventHandler(eventId, eventHandler)) {
      return; // event handler already registered
    }
    if (!(eventId in this.eventHandlers)) {
      this.eventHandlers[eventId] = [];
    }
    this.eventHandlers[eventId].push(eventHandler);
  }

  removeEventHandler(eventId, eventHandler) {
    const handlers = this.eventHandlers[eventId];
    const result = [];
    const n = handlers.length;
    for (let i = 0; i < n; i++) {
      const handler = handlers[i];
      if (handler !== eventHandler) {
        result.push(handler);
      }
    }
  }

  dispatchEvent(/* String */ eventId) {
    if (!this.isValidEventName(eventId)) {
      throw "invalid event id for LaCmok " + eventId;
    }
    if (!(eventId in this.eventHandlers)) {
      console.info("No event handler registered jet for '" + eventId + "'");
      return;
    }
    const handlers = this.eventHandlers[eventId];
    for (let i = 0; i < handlers.length; i++) {
      const handler = handlers[i];
      handler();
    }
  }
}
