export class FirebaseAPICache {
  storage = null;
  prefix = null;

  constructor() {
    this.prefix = "comk";
    this.storage = sessionStorage;
  }

  getKey(key) {
    return this.prefix + key;
  }

  hasItem(key) {
    const k = this.getKey(key);
    return this.storage.getItem(k) != null;
  }

  getItem(key) {
    const k = this.getKey(key);
    return this.storage.getItem(k);
  }

  setItem(key, value) {
    const k = this.getKey(key);
    return this.storage.setItem(k, value);
  }
}
