import React from "react";
import Header from "./Header";
import "./App.css";

import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Catalog } from "./Catalog";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { FilmPage } from "../pages/FilmPage";
import { MemberPage } from "../pages/MemberPage";
import { EditFilmPage } from "../pages/EditFilmPage.js";
import PageNotFound from "../pages/NotFound";
import CatalogItemKind from "../consts/CatalogItemKind.js";
import ScrollButton from "./ScrollToTop";
import { AboutPage } from "../pages/AboutPage";

function App(props) {
  const { t } = useTranslation();
  const filmModel = props.catalog;
  const filmEvents = props.catalogController;
  const firebaseAPI = props.firebaseAPI;
  const tmdbAPI = props.tmdbAPI;
  const userModel = props.userModel;
  return (
    <div className="App">
      <Header t={t} catalog={filmModel} catalogController={filmEvents} />
      <Routes>
        <Route
          path="/"
          element={
            <CatalogPage catalog={filmModel} catalogController={filmEvents} fbApi={firebaseAPI} />
          }
        />
        <Route
          path="/filter"
          element={
            <CatalogPage catalog={filmModel} catalogController={filmEvents} fbApi={firebaseAPI} />
          }
        />
        <Route
          path="/film/:id"
          element={<FilmPage catalog={filmModel} controller={filmEvents} />}
        />
        <Route
          path="/member/:mid"
          element={<MemberPage catalog={filmModel} controller={filmEvents} fbApi={firebaseAPI} />}
        />
        <Route
          path="/newFilm"
          element={
            <EditFilmPage
              controller={filmEvents}
              firebaseAPI={firebaseAPI}
              tmdbAPI={tmdbAPI}
              catalog={filmModel}
              userModel={userModel}
            />
          }
        />
        <Route
          path="/film/:id/edit"
          element={
            <EditFilmPage
              controller={filmEvents}
              firebaseAPI={firebaseAPI}
              tmdbAPI={tmdbAPI}
              catalog={filmModel}
              userModel={userModel}
            />
          }
        />
        <Route path="about" element={<AboutPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer t={t} />
      <ScrollButton />
    </div>
  );
}

function CatalogPage(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const kind = searchParams.get("kind");
  const genre = searchParams.get("genre");
  const lang = searchParams.get("lang");
  const user = searchParams.get("user");
  const fbApi = props.fbApi;

  const handleSearchParams = (catalog, searchParams) => {
    let checkedSearchParams = {};
    if (kind) {
      checkedSearchParams = { ...checkedSearchParams, kind };
      catalog.filterByKind(kind);
    } else {
      catalog.filterByKind(CatalogItemKind.EMPTY);
    }

    if (genre) {
      checkedSearchParams = { ...checkedSearchParams, genre };
      catalog.filterByGener(genre);
    } else {
      catalog.filterByGener("");
    }

    if (lang) {
      checkedSearchParams = { ...checkedSearchParams, lang };
      catalog.filterByLang(lang);
    } else {
      catalog.filterByLang("");
    }
    if (user) {
      catalog.filterByUser(user);
    } else {
      catalog.filterByUser("");
    }
    return checkedSearchParams;
  };

  handleSearchParams(props.catalog, searchParams);

  return (
    <div>
      {/* {(lang || genre || kind === 'f' || kind === "s") ? "" : <Promo />} */}
      <Catalog t={t} model={props.catalog} controller={props.catalogController} fbApi={fbApi} />
    </div>
  );
}

export default App;
