export class HTTPRequest {
  xhr = null;
  url = "";
  headers = [];

  constructor(url, headers) {
    this.url = url;
    this.headers = headers;
    this.xhr = new XMLHttpRequest();
  }

  getURL(params, onload, onerror) {
    this.xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        try {
          const json = JSON.parse(this.responseText);
          onload(json);
        } catch (error) {
          onerror(error);
        }
      } else if (this.readyState === 4 && this.status !== 200) {
        const json = JSON.parse(this.responseText);
        onerror(json);
      }
      console.log("INFO: HTTPRequest.status: " + this.readyState);
    };
    this.xhr.open("GET", this.url, true);
    this.xhr.send();
  }

  postURL() {}
}
