import React, { useState } from "react";
import CategoriesFilter from "./CategoriesFilter";
import CatalogItemKind from "../consts/CatalogItemKind";
import { BsSearch } from "react-icons/bs";
import Search from "./Search";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Form from "react-bootstrap/Form";

import "./Header.css";
import getCurrentSearchParams from "../helpers/SearchParamsHelper";

function Header(props) {
  const navigate = useNavigate();
  const [cmokHue, setCmokHue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const kind = CatalogItemKind;
  let langToggle = searchParams.get("lang") === "be";
  function filterByLang(checked) {
    langToggle = checked;
    const currentSearchParams = getCurrentSearchParams(searchParams);
    navigateAndUpdateSearchParams(currentSearchParams, "lang", checked ? "be" : false);
  }
  const changeColor = () => {
    const value = cmokHue + 30 + Math.random() * 80;
    setCmokHue(value > 360 ? value - 360 : value);
  };
  const navigateAndUpdateSearchParams = (currentSearchParams, type, value) => {
    let updatedSearchParams = { ...currentSearchParams, [type]: value };
    if (type === "lang" && value === false) {
      const { lang, ...clearedLangSearchParams } = updatedSearchParams;
      updatedSearchParams = clearedLangSearchParams;
    }

    navigate(
      {
        pathname: "../filter",
        search: `?${createSearchParams(updatedSearchParams)}`,
      },
      { replace: true }
    );
  };

  const handleClick = (v) => {
    const currentSearchParams = getCurrentSearchParams(searchParams);
    navigateAndUpdateSearchParams(currentSearchParams, "kind", v);
  };
  const { t } = props;
  return (
    <header className="App-header header-row">
      <div className="title">
        <Link to={"/"}>
          <img
            style={{ filter: `hue-rotate(${cmokHue}deg)` }}
            className="logo"
            src="/assets/la-cmok.svg"
            alt="Лагатып старонкі малюнак маленькага цмока за ноўтбукам"
          />
        </Link>
      </div>
      <div className="header-control-wrapper">
        <div className="filter">
          <Stack direction="vertical" gap={2}>
            <Stack direction="horizontal" gap={2}>
              <ToggleButtonGroup
                name="kindFilter"
                size="md"
                type="radio"
                value={searchParams.get("kind")}
                onChange={handleClick}
              >
                <ToggleButton id="use" value={kind.USE}>
                  {t("use")}
                </ToggleButton>
                <ToggleButton id="filmy" value={kind.FILM}>
                  {t("filmy")}
                </ToggleButton>
                <ToggleButton id="seryaly" value={kind.SERYAL}>
                  {t("seryjaly")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <CategoriesFilter
              prompt={t("zanr")}
              loading={t("zahruzka")}
              model={props.catalog}
              controller={props.catalogController}
            />
            <Form.Check
              checked={langToggle}
              className="form-switch-lg"
              size="md"
              type="switch"
              label="толькі агучанае"
              id="dub-only"
              onChange={(e) => filterByLang(e.currentTarget.checked)}
            />
          </Stack>
        </div>
        <div className="search">
          <div className="searchIcon">
            <BsSearch />
          </div>
          <Search model={props.catalog} controller={props.catalogController} t={t} />
        </div>
      </div>
    </header>
  );
}

export default Header;
