import { BaseValidator } from "./BaseValidator";
import { isUndefined } from "webpack-merge/dist/utils";

export class IMDBIDValidator extends BaseValidator {
  filedName = null;
  pattern = null;

  constructor(/* String */ fieldName) {
    super();
    this.filedName = fieldName;
    this.pattern = /tt[0-9]+/;
  }

  validate(input) {
    super.validate(input);
    if (input === null || input === "" || isUndefined(input)) {
      return;
    }
    if (!this.pattern.test(input)) {
      this.errors.push(`Filed '${this.filedName}' has wrong id format`);
    }
  }
}
