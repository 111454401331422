export class AuthChangedCommand {
  execute(
    /* FirebaseAPI */ firebaseAPI,
    /* UserModel */ userModel,
    /* CatalogModel */ filmCatalog
  ) {
    console.info("INFO: AuthChangedCommand");
    const user = firebaseAPI.currentUser();
    userModel.user = user;
    if (user) {
      filmCatalog.filterByPublished(false);
    } else {
      filmCatalog.filterByPublished(true);
    }
  }
}
