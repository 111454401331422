export class BaseValidator {
  errors = null;
  input = null;

  validate(input) {
    this.errors = [];
    this.input = input;
  }

  isValid() {
    return this.errors == null || this.errors.length === 0;
  }

  getErrorMessage() {
    if (!this.isValid()) {
      return this.errors[0];
    }
    return null;
  }
}
