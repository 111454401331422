import { Episode } from "../../model/data/Episode";
import { Season } from "../../model/data/Season";
import { JSONProxy } from "./JSONProxy";

export class VideoSetDataMapper {
  proxy = null;

  constructor(data) {
    this.proxy = new JSONProxy(data);
  }

  object2tv(prop, defaultValue = null, mondatory) {
    if (!this.proxy.has(prop)) {
      return defaultValue;
    }
    const filmId = this.proxy.str("id", "", true);
    const arr = this.proxy.obj(prop);
    const result = [];
    for (const seasonName in arr) {
      const seasonData = arr[seasonName];
      const videos = this.parseVideoSet(filmId, seasonName, seasonData);
      const season = new Season(seasonName, seasonName, videos);
      result.push(season);
    }
    return result;
  }

  tv2object(prop, defaultValue = null, mondatory) {
    if (!this.proxy.has(prop)) {
      return defaultValue;
    }
    const seasons = this.proxy.obj(prop);
    if (seasons == null || seasons.length === 0) {
      return defaultValue;
    }
    const result = {};
    for (let i = 0; i < seasons.length; i++) {
      const season = seasons[i];
      const seasonJson = {};
      for (let i = 0; i < season.episodes.length; i++) {
        const e = season.episodes[i];
        seasonJson[e.title] = e.url;
      }
      result[season.title] = seasonJson;
    }
    return result;
  }

  object2season(prop, defaultValue = null, mondatory) {
    if (!this.proxy.has(prop)) {
      return defaultValue;
    }
    const value = this.proxy.obj(prop);
    if (value == null) {
      return defaultValue;
    }
    const filmId = this.proxy.str("id", "", true);
    let videos = [];
    let sid = "";
    if (typeof value === "object") {
      sid = "mova";
      videos = this.parseVideoSet(filmId, sid, value);
    } else {
      const episode = this.crateVideoData(filmId, sid, prop, this.proxy);
      videos = [episode];
    }
    const result = new Season(sid, sid, videos);
    return result;
  }

  season2object(prop, defaultValue = null, mondatory) {
    if (!this.proxy.has(prop)) {
      return defaultValue;
    }
    const season = this.proxy.obj(prop);
    if (season == null || season.episodes == null || season.episodes.length === 0) {
      return defaultValue;
    }
    const result = {};
    for (let i = 0; i < season.episodes.length; i++) {
      const e = season.episodes[i];
      result[e.title] = e.url;
    }
    return result;
  }

  /**
   * Convert plain object of video urls to array of VideoData.
   *
   * @param {string} seasonName
   * @param {object} jsonObj plain object with video urls
   * @return {Array.<Episode>}
   */
  parseVideoSet(filmId, seasonName, jsonObj) {
    const result = [];
    const proxy = new JSONProxy(jsonObj);
    for (const key in jsonObj) {
      result.push(this.crateVideoData(filmId, seasonName, key, proxy));
    }
    return result;
  }

  crateVideoData(filmId, sid, key, proxy) {
    const url = proxy.str(key);
    const episodeId = this.createFullEpisodeId(filmId, sid, key);
    const result = new Episode(episodeId, key, url);
    result.filmId = filmId;
    result.seasonId = sid;
    return result;
  }

  /**
   * @param {string} filmId is a Film or TV Show id
   * @param {string} sid identifier of season (could be just number)
   * @param {string} eid identifier of episod (could be just number)
   * @return {string}
   */
  createFullEpisodeId(filmId, sid, eid) {
    return filmId + "." + sid + "." + eid;
  }
}
