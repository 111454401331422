import React from "react";
import "./NotFound.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export function AboutPage() {
  const { t } = useTranslation();
  return (
    <div className="not-found">
      <Helmet>
        <title>Пра цмок | {t("site-title")}</title>
      </Helmet>
      <h1>Пра нас</h1>
      <p>
        Лагодны Цмок — некамэрцыйная пляцхорма з фільмамі а сэрыяламі па-беларуску, дзе кажны ахвочы
        можа задарма апублікаваць свае пераклады й агучваньні.
      </p>
      <a href="https://t.me/la_cmok" target="_blank" rel="noreferrer">
        Сачыць за навінамі ў Telegram
      </a>
      <a href="https://t.me/la_cmok_bot" target="_blank" rel="noreferrer">
        Напісаць нам
      </a>
      <a href="https://forms.gle/GfPzDErJVGphMSNr7" target="_blank" rel="noreferrer">
        Прапанаваць да перакладу
      </a>
      <a
        href="https://la-cmok.notion.site/0c5ad9c0bd9748d3a815f8ba856b79c9"
        target="_blank"
        rel="noreferrer"
      >
        Як апублікаваць?
      </a>
      <h1>Дысклеймер</h1>
      <p>
        Калі вы ўважаеце, што вашыя аўтарскія правы парушаныя, калі ласка, напішэце нам на пошту
        cmok.lahodny@gmail.com
      </p>
    </div>
  );
}
