import React, { useRef, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FcNext, FcPrevious } from "react-icons/fc";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./EpisodeButtons.css";

function EpisodeButtons(props) {
  const list = props.videosArray;
  const swiperSlides = [];
  const episodes = [];
  const [swiper, setSwiper] = useState(null);
  const [isSwiperDisplayed, setIsSwiperDisplayed] = useState(false);

  function simpleEpisodeButton(video) {
    const title = video.title;
    const url = video.url;
    episodes.push(
      <div
        key={title}
        className={"video-button" + (props.selectedEpisod === url ? " selected" : "")}
        onClick={() => {
          props.onSelectEpisode(url);
        }}
      >
        {title + (title.length > 3 ? "" : ` сэрыя`)}
      </div>
    );
  }

  function addSwiperEpisode(video, attributes) {
    const title = video.title;
    const url = video.url;
    swiperSlides.push(
      <SwiperSlide key={title}>
        <div
          {...attributes}
          className={"video-button" + (props.selectedEpisod === url ? " selected" : "")}
          onClick={() => {
            props.onSelectEpisode(url);
          }}
        >
          {title + (title.length > 3 ? "" : ` сэрыя`)}
        </div>
      </SwiperSlide>
    );
  }

  const breakpoints = {
    750: {
      slidesPerView: 7,
      slidesPerGroup: 7,
      spaceBetween: 10,
    },
    520: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 0,
    },
    360: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 0,
    },
  };

  const hideSwiper = () => {
    setIsSwiperDisplayed(false);
  };

  const checkSwiperDisplay = (breakpoint) => {
    if (props.videosArray.length < breakpoint.slidesPerGroup + 1) {
      console.log("Slides per group: " + breakpoint.slidesPerGroup);
      hideSwiper();
    }
  };

  if (list.length > 4 && isSwiperDisplayed) {
    const refSwiperPrevArrow = useRef(null);
    const refSwiperNextArrow = useRef(null);
    for (let i = 0; i < list.length; i++) {
      addSwiperEpisode(list[i]);
      if (list.length < 8) {
        simpleEpisodeButton(list[i]);
      }
    }
    return (
      <div className="slider-row">
        <div
          className="custom-slider-previous swiper-prev-arrow"
          ref={refSwiperPrevArrow}
          onClick={() => swiper.slidePrev()}
        >
          <FcPrevious />
        </div>
        <Swiper
          navigation={{
            prevEl: refSwiperPrevArrow.current,
            nextEl: refSwiperNextArrow.current,
          }}
          onSwiper={setSwiper}
          breakpoints={breakpoints}
          modules={[Navigation]}
          slidesPerGroup={3}
          slidesPerView={3}
          spaceBetween={0}
          onBreakpoint={(swiper, breakpoint) => {
            checkSwiperDisplay(breakpoint);
          }}
        >
          {swiperSlides}
        </Swiper>
        <div
          className="custom-slider-next swiper-next-arrow"
          ref={refSwiperNextArrow}
          onClick={() => swiper.slideNext()}
        >
          <FcNext />
        </div>
      </div>
    );
  } else {
    for (let i = 0; i < list.length; i++) {
      simpleEpisodeButton(list[i]);
    }
    return <div className={"episode-list"}>{episodes}</div>;
  }
}

export default EpisodeButtons;
