export class Season {
  static GetSeason(seasonName /* String */, seasons /* Array.<Season> */) {
    if (seasons == null) {
      return null;
    }
    for (let si = 0; si < seasons.length; si++) {
      const season = seasons[si];
      if (season !== null && season.title === seasonName) {
        return season;
      }
    }
    return null;
  }

  static CreateSeasonList(filmId, /* Episode */ episode) {
    const season = new Season(episode.seasonId, episode.seasonId, [episode]);
    const result = [season];
    return result;
  }

  static RemoveVideo(episode /* Episode */, seasons /* Array.<Season> */) {
    const seasonName = episode.getSeasonName();
    const season = this.GetSeason(seasonName, seasons);
    if (season == null) {
      return;
    }
    season.deleteEpisode(episode);
    if (season.isEmpty()) {
      seasons.splice(season, 1);
    }
  }

  static AddVideo(seasons, episode) {
    let season = this.GetSeason(episode.seasonId, seasons);
    if (season == null) {
      season = new Season(episode.seasonId, episode.seasonId, []);
      seasons.push(season);
    }
    season.addEpisode(episode);
  }

  constructor(sid, title, videos) {
    this.sid = sid;
    this.title = title;
    this.episodes = videos;
  }

  /**
   * @type {Array.<Episode>}
   */
  episodes = [];

  /**
   * Name of the video set
   * @type {string}
   */
  title = "";

  /**
   * Set Id
   */
  sid = "";

  isEmpty() {
    return this.episodes.length === 0;
  }

  size() {
    return this.episodes.length;
  }

  addEpisode(episode /* Episode */) {
    this.episodes.push(episode);
  }

  deleteEpisode(episode) {
    const result = [];
    for (let i = 0; i < this.episodes.length; i++) {
      const e = this.episodes[i];
      if (episode.key === e.key) {
        continue;
      }
      result.push(e);
    }
    this.episodes = result;
  }
}
