export class VideoQuality {
  static get SD() {
    return "SD";
  }

  static get HD() {
    return "HD";
  }

  static get FHD() {
    return "FHD";
  }
}
