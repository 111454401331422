import Button from "react-bootstrap/Button";
import { MdWarning } from "react-icons/md";
import React from "react";
import { useTranslation } from "react-i18next";

export function ReportErrorButton() {
  const { t } = useTranslation();
  return (
    <a target="_blank" href="https://forms.gle/Ka68iYaV2wszEqvN9" rel="noreferrer">
      <Button size="sm" variant="secondary">
        <MdWarning /> {t("pavedamic-pra-pamylku")}
      </Button>
    </a>
  );
}
