import React, { useState } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CatalogItemKind from "../consts/CatalogItemKind";

export function CatalogItemKindSwitch(props) {
  // STATE
  const [value, setValue] = useState(CatalogItemKind.FILM);
  // CONST
  const initialValue = props.value;
  const onChange = props.onChange;
  const { t } = useTranslation();
  const radios = [
    { name: t("film"), value: CatalogItemKind.FILM },
    { name: t("seryal"), value: CatalogItemKind.SERYAL },
  ];

  if (initialValue !== value) {
    setValue(initialValue);
  }

  function handleChange(e) {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <div className={props.className}>
      <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            name="radio"
            value={radio.value}
            checked={value === radio.value}
            onChange={handleChange}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
}
