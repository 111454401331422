export default class CatalogItemKind {
  static get EMPTY() {
    return "";
  }

  static get USE() {
    return "use";
  }

  static get FILM() {
    return "f";
  }

  static get SERYAL() {
    return "s";
  }

  static get ASERYAL() {
    return "as";
  }

  static get AFILM() {
    return "af";
  }
}
