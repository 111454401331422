import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./FilmRepeater.css";
import { CatalogPoster } from "./CatalogPoster";

export function FilmRepeater({
  showTitle /* String */,
  images /* Array.<FilmModel> */,
  empty,
  fbApi,
}) {
  if (images == null || images.length === 0) {
    return (
      <Row xs={3} md={4} lg={4} xl={5} xxl={6} className="g-2">
        <Col className="film-poster-col">{empty}</Col>
      </Row>
    );
  }
  const posters = [];
  for (let index = 0; index < images.length; index++) {
    const poster = <CatalogPoster film={images[index]} showTitle={showTitle} fbApi={fbApi} />;
    posters.push(poster);
  }
  return (
    <Row xs={3} md={4} lg={4} xl={5} xxl={6} className="g-2">
      {posters}
    </Row>
  );
}
