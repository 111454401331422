import { JSONProxy } from "./JSONProxy";
import { VideoSetDataMapper } from "./VideoSetDataMapper";

export class JsonDataMapper {
  static get FILM_PROP_CONFIG() {
    return [
      // JSON,   JSON_TYPE,    OBJ,      OBJ_TYPE, DEFAULT, MONDATORY
      ["id", "s", "id", "s", "", true], // eslint-disable-line
      ["nazva", "s", "title", "s", "", true],  // eslint-disable-line
      ["kind", "s", "kind", "s", "", false],  // eslint-disable-line
      ["druhaNazva", "s", "sndTitle", "s", "", false], // eslint-disable-line
      ["pakazvac", "s", "display", "b", "", false], // eslint-disable-line
      ["hod", "i", "year", "s", "", false], // eslint-disable-line
      ["abnavleno", "s", "updated", "d", new Date(0), false], // eslint-disable-line
      ["zanr", "s", "genre", "a", [], false], // eslint-disable-line
      ["razmer", "s", "videoSize", "s", [], false], // eslint-disable-line
      ["tah", "s", "tag", "a", [], false], // eslint-disable-line
      ["mova", "s", "lang", "a", [], false], // eslint-disable-line
      ["apisanne", "s", "desc", "s", "", false], // eslint-disable-line
      ["reit", "s", "rating", "s", "", false], // eslint-disable-line
      ["ext", "s", "ext", "s", "mp4", false], // eslint-disable-line
      ["notes", "s", "note", "s", "", false], // eslint-disable-line
      ["imdbId", "s", "imdbId", "s", "", false], // eslint-disable-line
      ["vytvorcasc", "s", "studio", "a", [], false], // eslint-disable-line
      ["pieraklad", "s", "translators", "a", [], false], // eslint-disable-line
      ["redahavannie", "s", "editors", "a", [], false], // eslint-disable-line
      ["ahucvannie", "s", "dubbers", "a", [], false], // eslint-disable-line
      ["cas", "i", "duration", "i", "", false], // eslint-disable-line
      ["videoiframe", "o", "videos", "season", null, false], // eslint-disable-line
      ["series", "o", "seasons", "tv", [], false], // eslint-disable-line
      ["sub", "s", "subtitleLang", "s", "xx", false] // eslint-disable-line
    ];
  }

  static fromJSON(json, toObject, properties) {
    const jsonProxy = new JSONProxy(json);
    const config = properties || this.FILM_PROP_CONFIG;
    for (let i = 0; i < config.length; i++) {
      const p = config[i];
      const fromType = p[1];
      const toType = p[3];
      const key = fromType + ":" + toType;
      const fromProp = p[0];
      const toProp = p[2];
      const defaultValue = p[4];
      const mondatory = p[5];
      if (jsonProxy.has(fromProp)) {
        const value = this.getValue(jsonProxy, key, fromProp, defaultValue, mondatory);
        this.setValue(toObject, toProp, value, defaultValue, true);
      } else {
        this.setValue(toObject, toProp);
      }
    }
  }

  static toJSON(inputObj) {
    const result = {};
    const proxy = new JSONProxy(inputObj);
    const config = this.FILM_PROP_CONFIG;
    for (let i = 0; i < config.length; i++) {
      const p = config[i];
      const fromType = p[1];
      const toType = p[3];
      const key = toType + ":" + fromType;
      const fromProp = p[0];
      const toProp = p[2];
      const defaultValue = p[4];
      const mondatory = p[5];
      if (proxy.has(toProp)) {
        const value = this.getValue(proxy, key, toProp, defaultValue, mondatory);
        this.setValue(result, fromProp, value, defaultValue, false);
      }
    }
    return result;
  }

  static setValue(obj, /* String */ prop, /* Any */ value, defaultValue, setEmpyValue = false) {
    if ((value === null || value === "" || value === 0) && !setEmpyValue) {
      return;
    }
    if (value === defaultValue && !setEmpyValue) {
      return;
    }
    obj[prop] = value;
  }

  static getValue(
    /* JSONProxy */ fromObj,
    /* TypeConvertation key */ key,
    fromProp,
    defaultValue,
    mondatory
  ) {
    const c = new VideoSetDataMapper(fromObj.data);
    switch (key) {
      case "i:s":
      case "s:s":
        return fromObj.str(fromProp, defaultValue, mondatory);
      case "s:b":
        return fromObj.bool(fromProp, defaultValue, mondatory);
      case "b:s":
        return fromObj.boolToStr(fromProp, defaultValue, mondatory);
      case "s:d":
        return fromObj.date(fromProp, defaultValue, mondatory);
      case "d:s":
        return fromObj.dateToStr(fromProp, defaultValue, mondatory);
      case "s:a":
        return fromObj.strArray(fromProp, defaultValue, mondatory);
      case "i:i":
      case "s:i":
        return fromObj.int(fromProp, defaultValue, mondatory);
      case "a:s":
        return fromObj.arrayToStr(fromProp, defaultValue, mondatory);
      case "o:season":
        return c.object2season(fromProp, defaultValue, mondatory);
      case "season:o":
        return c.season2object(fromProp, defaultValue, mondatory);
      case "o:tv":
        return c.object2tv(fromProp, defaultValue, mondatory);
      case "tv:o":
        return c.tv2object(fromProp, defaultValue, mondatory);
    }
    console.warn(`JsonDataMapper.mapProperty, unknown key: '${key}'`);
    return undefined;
  }
}
