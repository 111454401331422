import { Char } from "./Char";
import { NLToken } from "./NLToken";
import { NLTokenType } from "./NLTokenType";
import { ParserError } from "./ParserError";

export class NLLexer {
  _tokens = [];
  _isValid = false;
  _input = null;
  _pos = 0;

  constructor() {}

  isValid() {
    return this._isValid;
  }

  getTokens() {
    return this._tokens;
  }

  getInput() {
    return this._input;
  }

  reset(input) {
    this._pos = 0;
    this._input = input === null ? "null" : "" + input;
    this._tokens = [];
  }

  prevChar(token) {
    this._pos--;
    if (token != null) {
      token.end = this._pos;
    }
  }

  nextChar(token) {
    this._pos++;
    if (token != null) {
      token.end = this._pos;
    }
  }

  peek(increment = 0) {
    if (this._input == null || this._pos + increment >= this._input.length) {
      return null;
    } else {
      return this._input.codePointAt(this._pos + increment);
    }
  }

  match(code) {
    return this.peek() === code;
  }

  matchAny(codes) {
    const code = this.peek();
    return codes.indexOf(code) !== -1;
  }

  setValue(t) {
    if (t === undefined) {
      console.log("!");
    }
    t.value = t.end > t.start ? this._input.substring(t.start, t.end) : null;
  }

  startToken(kind) {
    const token = new NLToken(kind, this._pos, this._pos);
    this.nextChar(token);
    return token;
  }

  readAtom(input) {
    this.reset(input);
    const token = this.readToken();
    this.setValue(token);
    return token;
  }

  readExpr(input) {
    let token = this.readAtom(input);
    while (token.kind !== NLTokenType.EOL && token.kind !== NLTokenType.UNDEFINED) {
      if (
        !(
          token.start === token.end ||
          token.kind === NLTokenType.T_SPACE ||
          token.kind === NLTokenType.T_SKIP
        )
      ) {
        this._tokens.push(token);
      }
      token = this.readToken();
      this.setValue(token);
    }
    this._isValid = this._pos >= input.length;
    if (!this._isValid) {
      const code = token.value.codePointAt(0);
      throw new ParserError(
        0,
        token.start,
        `Invalid character #${code} at ${this._pos} '${token}'. ` + this._input
      );
    }
    return this._tokens;
  }

  stringify() {
    let result = "";
    let space = "";
    for (let i = 0; i < this._tokens.length; i++) {
      const token = this._tokens[i];
      switch (token.kind) {
        case NLTokenType.PARENTHESIS:
          if (token.value === "(") {
            result += " (";
            space = "";
          } else if (token.value === ")") {
            result += ")";
            space = " ";
          }
          break;
        case NLTokenType.WORD:
        case NLTokenType.NUM:
        case NLTokenType.INT:
          result += space + token.value;
          space = " ";
          break;
        case NLTokenType.TIME:
          result += space + "<TIME>";
          space = " ";
          break;
        case NLTokenType.DATE:
          result += space + "<DATE>";
          space = " ";
          break;
        case NLTokenType.END_OF_PHRASE:
          result += "|";
          space = "";
          break;
        case NLTokenType.PUNCTUATION:
        case NLTokenType.T_AMP_ESCAPE:
        default:
          break;
      }
    }
    return result;
  }

  readToken() {
    if (this.isEOF()) {
      return this.startToken(NLTokenType.EOL);
    }
    if (this.matchAny(Char.WHITESPACE)) {
      return this.readWhiteSpaceToken();
    }
    if (this.match(Char.CH_AMPERSAND)) {
      return this.readAmpToken();
    }
    if (this.matchAny(Char.PARENTHESIS)) {
      return this.readParenthesis();
    }
    if (this.isDigit()) {
      return this.readNumberToken();
    }
    if (this.isLetter()) {
      return this.readWordToken();
    }
    if (this.isEndOfPhrase()) {
      return this.readEndOfPhrase();
    }
    if (this.isPunctiation() || this.isMath() || this.isSpec()) {
      return this.readPunctuation();
    }
    // if (this.matchAny(Char.SKIP)) {
    return this.readSkipToken();
    // }
    // return this.startToken(0);
  }

  isEOF() {
    return this._pos >= this._input.length;
  }

  isDigit() {
    if (this.peek() >= Char.CH_NUM_0) {
      return this.peek() <= Char.CH_NUM_9;
    } else {
      return false;
    }
  }

  isUpperLetter() {
    if (this.peek() >= Char.CH_UPPER_A) {
      return this.peek() <= Char.CH_UPPER_Z;
    } else {
      return false;
    }
  }

  isLowerLetter() {
    if (this.peek() >= Char.CH_LOW_A) {
      return this.peek() <= Char.CH_LOW_Z;
    } else {
      return false;
    }
  }

  isLatinLetters() {
    return this.peek() >= 192 && this.peek() <= 696;
  }

  isCyrillicLetters() {
    return this.peek() >= 913 && this.peek() <= 1281;
  }

  isLetter() {
    if (
      this.isUpperLetter() ||
      this.isLowerLetter() ||
      this.isLatinLetters() ||
      this.isCyrillicLetters()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isEndOfPhrase() {
    return this.matchAny(Char.EOP);
  }

  isPunctiation() {
    return this.matchAny(Char.PUNCTUATION);
  }

  isMath() {
    return this.matchAny(Char.MATH);
  }

  isSpec() {
    return this.matchAny(Char.SPECIAL);
  }

  readNumberToken() {
    const token = this.startToken(NLTokenType.INT);
    this.appendDigit(token);
    const nextCharIsDigit = this.peek(1) >= Char.CH_NUM_0 && this.peek(1) <= Char.CH_NUM_9;
    if (this.match(Char.CH_DOT) && nextCharIsDigit) {
      token.kind = NLTokenType.NUM;
      this.nextChar(token);
      this.appendDigit(token);
    } else if (this.match(Char.CH_COLON) && nextCharIsDigit) {
      token.kind = NLTokenType.TIME;
      this.nextChar(token);
      this.appendDigit(token);
      const nextCharIsDigit = this.peek(1) >= Char.CH_NUM_0 && this.peek(1) <= Char.CH_NUM_9;
      if (this.match(Char.CH_COLON) && nextCharIsDigit) {
        this.nextChar(token);
        this.appendDigit(token);
      }
    } else if (this.match(Char.CH_FORWARD_SLASH) && nextCharIsDigit) {
      token.kind = NLTokenType.DATE;
      this.nextChar(token);
      this.appendDigit(token);
      const nextCharIsDigit = this.peek(1) >= Char.CH_NUM_0 && this.peek(1) <= Char.CH_NUM_9;
      if (this.match(Char.CH_FORWARD_SLASH) && nextCharIsDigit) {
        this.nextChar(token);
        this.appendDigit(token);
      }
    }
    return token;
  }

  appendDigit(token) {
    while (this.isDigit() && !this.isEOF()) {
      this.nextChar(token);
    }
  }

  readAmpToken() {
    const token = this.startToken(NLTokenType.T_AMP_ESCAPE);
    this.nextChar(token);
    if (this.match(Char.CH_NUMBER_SIGN)) {
      this.nextChar(token);
    }
    while (this.isLetter() || this.isDigit()) {
      this.nextChar(token);
    }
    if (this.match(Char.CH_SEMICOLON)) {
      this.nextChar(token);
    } else {
      token.kind = NLTokenType.PUNCTUATION;
      this._pos = token.end = token.start + 1;
    }
    return token;
  }

  readSkipToken() {
    const token = this.startToken(NLTokenType.T_SKIP);
    while (this.matchAny(Char.SKIP) && !this.isEOF()) {
      this.nextChar(token);
    }
    this.setValue(token);
    const code = token.value.codePointAt(0);
    console.log(`NGLexer.readSkipToke. ${token} #${code}`);
    return token;
  }

  readWhiteSpaceToken() {
    const token = this.startToken(NLTokenType.T_SPACE);
    while (this.matchAny(Char.WHITESPACE) && !this.isEOF()) {
      this.nextChar(token);
    }
    return token;
  }

  readParenthesis() {
    const token = this.startToken(NLTokenType.PARENTHESIS);
    this.setValue(token);
    return token;
  }

  readEndOfPhrase() {
    const token = new NLToken(NLTokenType.END_OF_PHRASE, this._pos, this._pos);
    while (this.isEndOfPhrase() && !this.isEOF()) {
      this.nextChar(token);
    }
    if (this.isEOF()) {
      token.kind = NLTokenType.EOL;
    }
    return token;
  }

  readPunctuation() {
    const token = new NLToken(NLTokenType.PUNCTUATION, this._pos, this._pos);
    while ((this.isMath() || this.isSpec() || this.isPunctiation()) && !this.isEOF()) {
      this.nextChar(token);
    }
    this.setValue(token);
    return token;
  }

  readWordToken() {
    const token = this.startToken(NLTokenType.WORD);
    while (
      (this.isLetter() || this.matchAny([Char.APOSTROPHE, Char.CH_MINUS_SIGN, Char.CH_ACCENT])) &&
      !this.isEOF()
    ) {
      this.nextChar(token);
    }
    this.setValue(token);
    return token;
  }
}
