export class ImageTool {
  canvasId = "canvas";
  canvasElement = null;
  canvasContext = null;
  imageElement = null;
  fitWidth = 250;
  fitHeight = 400;

  constructor(canvasId) {
    this.canvasId = canvasId;
    this.canvasElement = document.getElementById(canvasId);
    this.canvasContext = this.canvasElement.getContext("2d");
    this.imageElement = new Image();
    this.imageElement.crossOrigin = "anonymous";
  }

  fitOnLoad() {
    const imageWidth = this.imageElement.naturalWidth;
    const imageHeight = this.imageElement.naturalHeight;
    const factorWidht = this.fitWidth / imageWidth;
    const factorHeight = this.fitHeight / imageHeight;
    const fitFactor = factorWidht > factorHeight ? factorWidht : factorHeight;
    const newWidth = fitFactor * imageWidth;
    const newHeight = fitFactor * imageHeight;
    const offsetX = (this.fitWidth - newWidth) / 2;
    const offsetY = (this.fitHeight - newHeight) / 2;
    this.canvasElement.width = this.fitWidth;
    this.canvasElement.height = this.fitHeight;
    this.canvasContext.drawImage(this.imageElement, offsetX, offsetY, newWidth, newHeight);
  }

  // call on load
  fitWithoutBorders(imagePath, fitWidth, fitHeight) {
    this.fitWidth = fitWidth;
    this.fitHeight = fitHeight;
    this.imageElement.addEventListener("load", (e) => {
      this.fitOnLoad();
    });
    this.imageElement.src = imagePath;
  }

  resizeImage(imagePath, newWidth, newHeight) {
    this.imageElement.addEventListener("load", function () {
      const originalWidth = this.imageElement.naturalWidth;
      const originalHeight = this.imageElement.naturalHeight;
      const aspectRatio = originalWidth / originalHeight;
      if (newHeight === undefined) {
        newHeight = newWidth / aspectRatio;
      }
      this.canvasElement.width = newWidth;
      this.canvasElement.height = newHeight;
      this.canvasContext.drawImage(this.imageElement, 0, 0, newWidth, newHeight);
    });
    this.imageElement.src = imagePath;
  }

  cropImage(imagePath, downloadName, newX, newY, newWidth, newHeight) {
    this.imageElement.addEventListener("load", function () {
      const originalWidth = this.imageElement.naturalWidth;
      const originalHeight = this.imageElement.naturalHeight;
      const aspectRatio = originalWidth / originalHeight;
      if (newHeight === undefined) {
        newHeight = newWidth / aspectRatio;
      }
      this.canvasElement.width = newWidth;
      this.canvasElement.height = newHeight;
      this.canvasContext.drawImage(
        this.imageElement,
        newX,
        newY,
        newWidth,
        newHeight,
        0,
        0,
        newWidth,
        newHeight
      );
      this.downloadImage(downloadName);
    });
    this.imageElement.src = imagePath;
  }

  getBlob(onComplete) {
    this.canvasElement.toBlob((blob) => {
      const file = new File([blob], "thumbnail400.jpg", { type: "image/jpeg" });
      if (onComplete) {
        onComplete(file);
      }
    }, "image/jpeg");
  }

  downloadImage(downloadName) {
    const tempLink = document.createElement("a");
    tempLink.download = downloadName;
    tempLink.href = this.canvasElement.toDataURL("image/jpeg", 0.9);
    tempLink.click();
  }
}
