/**
 * Eco alternative for DI-frameworks
 */
import { CatalogModel } from "./model/CatalogModel";
import { FirebaseAPI } from "./api/FirebaseAPI";
import { UsersCatalogModel } from "./model/UsersCatalogModel";
import { FirebaseAPICache } from "./api/FirebaseAPICache";
import { LaCmokEventDispacher } from "./controller/LaCmokEventDispacher";
import { UserModel } from "./model/UserModel";
import { TMDBAPI } from "./api/TMDBAPI";
import { LaCmokCommandFactory } from "./controller/LaCmokCommandFactory";

export class LaCmokDependencyManager {
  controller = null;
  cache = null;
  fbAPI = null;
  tmdbAPI = null;
  catalogModel = null;
  userModel = null;
  usersCollectionModel = null;
  commandFactory = null;

  constructor() {
    this.controller = new LaCmokEventDispacher();
    this.cache = new FirebaseAPICache();
    this.fbAPI = new FirebaseAPI(this.controller, this.cache);
    this.tmdbAPI = new TMDBAPI();
    this.userModel = new UserModel();
    this.usersCollectionModel = new UsersCatalogModel(this.controller);
    this.catalogModel = new CatalogModel(this.controller, this.fbAPI, this.usersCollectionModel);
    this.commandFactory = new LaCmokCommandFactory(this, this.controller);
  }
}
