import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ImageTool } from "../../service/ImageTool";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import "./UploadImagePreview.css";
import { useTranslation } from "react-i18next";

export function UploadImagePreview(props) {
  // STATE
  const [posterURL, setPosterURL] = useState(null);
  const [posterFile, setPosterFile] = useState(null);
  // PROPS
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef(null);
  const resizeCallback = props.resizeCallback;
  const isClear = props.posterURL === "!EMPTY!";
  const initialValue = props.posterURL;
  const hasInitialValue =
    initialValue !== null && initialValue !== "" && initialValue !== "!EMPTY!";
  const hasValue = posterURL !== null || posterFile !== null;
  if (isClear && hasValue) {
    clear();
    return;
  } else if (!hasValue && hasInitialValue) {
    handleNewURL(props.posterURL);
    console.log("UploadImagePreview. Set props URL");
  }

  // FUNCTIONS
  function handleUploadClick(e) {
    hiddenFileInput.current.click();
  }

  function clear() {
    setPosterFile(null);
    setPosterURL(null);
    if (resizeCallback) resizeCallback(null);
  }

  function handleUpload(file) {
    if (file != null) {
      const tool = new ImageTool("posterCanvas");
      tool.fitWithoutBorders(URL.createObjectURL(file), 250, 400);
      updateParentComponent(tool);
    }
    setPosterURL(null);
    setPosterFile(URL.createObjectURL(file));
  }

  function handleNewURL(url) {
    if (url != null) {
      const tool = new ImageTool("posterCanvas");
      tool.fitWithoutBorders(url, 250, 400);
      updateParentComponent(tool);
    }
    setPosterURL(url);
    setPosterFile(null);
  }

  // TODO: Remove time, and update parent when canvas will have correct image to update it on parent
  function updateParentComponent(tool) {
    setTimeout(() => {
      tool.getBlob((f) => {
        if (resizeCallback) resizeCallback(f);
      });
    }, 5000);
  }

  // RENDER
  return (
    <div>
      <div className="poster-input-control">
        <input
          id="videoURL"
          className="form-control video-url"
          type="text"
          value={posterURL}
          onChange={(e) => {
            handleNewURL(e.target.value);
          }}
        />
        <Button
          style={{ display: hasValue ? "block" : "none" }}
          onClick={() => {
            clear();
          }}
        >
          <MdDelete />
        </Button>
      </div>
      <input
        hidden="true"
        name="image"
        multiple="false"
        type="file"
        ref={hiddenFileInput}
        accept="image/jpeg, image/jpg, image/png"
        onChange={(e) => {
          handleUpload(e.target.files[0]);
        }}
      />
      <div
        style={{ display: hasValue ? "none" : "block" }}
        className="upload-place"
        onClick={handleUploadClick}
      >
        <MdCloudUpload width={64} height={64} />
        <div>{t("Browse file")}</div>
      </div>
      <canvas
        style={{ display: hasValue ? "block" : "none" }}
        className="poster-canvas"
        id="posterCanvas"
        width="250"
        height="400"
        onClick={handleUploadClick}
      />
    </div>
  );
}
