import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import "./PageLoader.css";

export function PageLoader() {
  const { t } = useTranslation();
  return (
    <div className="preloader">
      <Spinner animation="border" role="status" />
      <div className="preloader-text">{t("zahruzka")}</div>
    </div>
  );
}
