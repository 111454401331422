import CatalogItemKind from "../consts/CatalogItemKind";
import { Season } from "./data/Season";

export class FilmModel {
  static get DEFAULT_DBID() {
    return "[!NEW_RECTORD]";
  }

  id = null;
  year = "";
  title = "";
  ext = "";
  genre = [];
  tag = [];
  lang = [];
  /** Age limitation of video */
  rating = "";
  videoSize = "640x480";
  videoWidth = 0; /* px */
  videoHeight = 0; /* px */
  duration = 0; /* sec */
  desc = "";
  updated = null;
  sndTitle = "";
  display = true;
  imdbId = "";
  subtitleLang = "";
  //
  studio = "";
  translators = []; /* nicknames of translators */
  editors = []; /* nicknames of editors */
  dubbers = []; /* nicknames of dubbers */
  note = "";

  /**
   * @type {Season}
   */
  videos = null;

  /**
   * Array of Video sets
   * @type {Array.<Season>}
   */
  seasons = [];
  posterReady = false;

  /**
   * This property store the video
   * @type {string}
   */
  poster = "";
  video = "";
  quality = ""; // Calculated fuild.
  durationText = "";

  /**
   * if dbid has valid value, object already saved in database
   */
  dbid = "[!NEW_RECTORD]";

  constructor(dbid = this.DEFAULT_DBID, kind = CatalogItemKind.FILM) {
    this.kind = kind;
    this.dbid = dbid;
    this.ext = "mp4";
    this.updated = new Date(0);
  }

  create() {
    this.buildUsers();
    this.buildTeam();
  }

  setSeasons(seasons) {
    if (this.kind === CatalogItemKind.FILM) {
      this.videos = seasons[0];
    } else {
      this.seasons = seasons;
    }
  }

  getSeasons() {
    if (this.kind === CatalogItemKind.FILM) {
      return this.videos == null ? null : [this.videos];
    } else {
      return this.seasons;
    }
  }

  // --------------------------------------------------------------------
  // TODO: Parsing & Progression callback must be moved in Service Layer
  // --------------------------------------------------------------------

  hasUser(user) {
    const result = user in this.users;
    return result;
  }

  buildUsers() {
    this.users = {};
    this.addUsers(this.translators);
    this.addUsers(this.editors);
    this.addUsers(this.dubbers);
    if (this.studio) {
      this.addUsers(this.studio);
    }
  }

  addUsers(users) {
    if (users == null || users.length === 0) return;
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (user in this.users) {
        continue;
      }
      this.users[user] = true;
    }
  }

  buildTeam() {
    let a = null;
    this.team = {};
    if ((a = this.arraysEqs([this.studio, this.translators, this.editors, this.dubbers]))) {
      this.team["peraklad-i-ahuchvanne-zahalovak"] = a;
    } else {
      if (this.studio) {
        this.team["studya-zahalovak"] = this.studio;
      }
      if ((a = this.arraysEqs([this.translators, this.editors, this.dubbers]))) {
        this.team["peraklad-i-ahuchvanne-zahalovak"] = a;
      } else if ((a = this.arraysEqs([this.translators, this.editors]))) {
        this.team["peraklad-i-redehavanne-zahalovak"] = a;
      } else {
        this.team["peraklad-zahalovak"] = this.translators;
        if ((a = this.arraysEqs([this.editors, this.dubbers]))) {
          this.team["redehavanne-i-ahuchvanne-zahalovak"] = a;
        } else {
          this.team["redehavanne-zahalovak"] = this.editors;
          this.team["ahuchvanne-zahalovak"] = this.dubbers;
        }
      }
    }
  }

  arraysEqs(arr) {
    let a = null;
    let b = null;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == null || arr[i].length === 0) {
        return null;
      }
      if (a == null || a === "") {
        a = arr[i];
        continue;
      }
      b = arr[i];
      if (!this.arraysEq(a, b)) {
        return null;
      }
    }
    return b;
  }

  arraysEq(a, b) {
    if (a == null && b != null) return false;
    if (a != null && b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  toString() {
    return "{FilmModel " + this.id + "}";
  }
}
