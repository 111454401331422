export class JSONProxy {
  data = null;

  constructor(data) {
    this.data = data;
  }

  has(/* String */ prop) {
    return (
      this.data != null &&
      prop != null &&
      prop in this.data &&
      this.data[prop] !== undefined &&
      this.data[prop] !== null
    );
  }

  arrayToStr(/* String */ prop, defaultValue = null) {
    let result = defaultValue;
    if (this.has(prop)) {
      result = this.data[prop].join(", ");
    }
    return result;
  }

  /**
   * Pars model property as string with coma separated values. This values
   * returned as result in array.
   *
   * for example:
   * model = { m: "Alex,Mila,Ila,234" }
   *
   * result = parseList(model, "m");
   * result = ["Slex", "Mila", "Ila", "234"]
   *
   * @param data
   * @param prop
   * @returns {*[]}
   */
  strArray(/* String */ prop, defaultValue = null) {
    const result = [];
    if (this.has(prop)) {
      const str = this.data[prop].toString();
      const arr = str.split(/\s*,\s*/);
      if (arr != null && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] !== null && arr[i] !== "") {
            result.push(arr[i]);
          }
        }
      }
    }
    return result.length === 0 ? defaultValue : result;
  }

  enum(/* String */ prop, /* Array */ values, /* */ defaultValue = "") {
    const result = this.str(prop, defaultValue);
    if (values !== null && values.length > 0) {
      const index = values.indexOf(result);
      if (index === -1) {
        throw (
          "JSONProxy.enum property '" + prop + "' do not belong to enum '" + values.join(",") + "'"
        );
      }
    }
    return result;
  }

  obj(/* String */ prop, defaultValue = null) {
    const result = this.has(prop) ? this.data[prop] : defaultValue;
    return result;
  }

  str(/* String */ prop, defaultValue = "", mondatory = false) {
    let result = defaultValue;
    if (this.has(prop)) {
      result = this.data[prop].toString();
    } else if (mondatory) {
      const jsonObj = JSON.stringify(this.data);
      throw `JSONProxy.str, do not have mondatory field '${prop}' in object: ` + jsonObj;
    }
    return result;
  }

  int(/* String */ prop, defaultValue = 0) {
    const result = this.has(prop) ? this.data[prop] : defaultValue;
    return parseInt(result);
  }

  /**
   * All values except "tak" is false.
   * @param prop
   * @param defaultValue
   * @returns {boolean}
   */
  bool(/* String */ prop, defaultValue = "tak") {
    const result = this.has(prop) ? this.data[prop] : defaultValue;
    return result !== "ne";
  }

  boolToStr(/* String */ prop, defaultValue = false) {
    const result = this.has(prop) && this.data[prop] ? "tak" : "ne";
    return result;
  }

  date(/* String */ prop, defaultValue = null) {
    const result = this.has(prop) ? new Date(this.data[prop]) : defaultValue;
    return result;
  }

  dateToStr(/* String */ prop, defaultValue = null) {
    const result = this.has(prop) ? this.data[prop].toJSON() : defaultValue;
    return result;
  }

  arr(/* String */ prop, defaultValue = null) {
    const result = this.has(prop) ? this.data[prop] : defaultValue;
    return result;
  }
}
