export default class CatalogGroups {
  static get NEW() {
    return "c1";
  }

  static get BEL() {
    return "c2";
  }

  static get REST() {
    return "c3";
  }

  static get UNPUBLISH() {
    return "c4";
  }
}
