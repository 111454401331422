import { LoadCatalogCommand } from "./command/LoadCatalogCommand";
import { LaCmokEvents } from "./LaCmokEvents";
import { AuthChangedCommand } from "./command/AuthChangedCommand";

/**
 * Store list of command, create them and execute on event handlers.
 * Commands my requre dependencies inside of them.
 */
export class LaCmokCommandFactory {
  constructor(/* LaCmokDependencyManager */ dm, /* LaCmokEventDispacher */ dispatcher) {
    dispatcher.addEventHandler(LaCmokEvents.LOAD_CATALOG, () => {
      const firebaseAPI = dm.fbAPI;
      const filmCatalog = dm.catalogModel;
      const userCatalog = dm.usersCollectionModel;
      new LoadCatalogCommand().execute(firebaseAPI, filmCatalog, userCatalog);
    });
    console.log("LaCmokCommandFactory, addEventHandler ");
    dispatcher.addEventHandler(LaCmokEvents.ON_AUTH_CHANGED, () => {
      new AuthChangedCommand().execute(dm.fbAPI, dm.userModel, dm.catalogModel);
    });
  }
}
