import { NLTokenType } from "../text-analys/NLTokenType";
import { BELacinka } from "./BELacinka";
import { NLLexer } from "../text-analys/NLLexer";

export class BETextTool {
  /**
   * Generate web save id from input string in cyrillic.
   * @param input
   * @returns {string}
   */
  static webSaveId(/* String */ input) {
    if (input === null || input === "") {
      return null;
    }
    const l = new NLLexer();
    const tokens = l.readExpr(input);
    const result = BETextTool.tokens2lat(tokens);
    return result.toLowerCase();
  }

  static tokens2lat(/* Array of NLToken */ tokens) {
    let result = "";
    let isNeedSplit = false;
    for (let i = 0; i < tokens.length; i++) {
      const t = tokens[i];
      switch (t.kind) {
        case NLTokenType.WORD:
          if (isNeedSplit) result += "-";
          result += BELacinka.toLatin(t.value, true);
          isNeedSplit = true;
          break;
        case NLTokenType.INT:
          if (isNeedSplit) result += "-";
          result += t.value;
          isNeedSplit = true;
          break;
        default:
          result += "_";
          break;
      }
    }
    return result;
  }
}
