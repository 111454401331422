import "../../pages/FilmPage.css";
import "../../pages/EditFilmPage.css";
import React from "react";
import { MdDelete } from "react-icons/md";
import Table from "react-bootstrap/Table";
import { Season } from "../../model/data/Season";
import { GoogleDriveTools } from "../../service/GoogleDriveTools";

export function EditSeasons(props) {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // PROPS
  const seasons /* FilmModel */ = props.seasons;
  if (seasons === null) {
    return "";
  }

  function deleteVideo(e) {
    console.log("Delete video: " + e.key);
    Season.RemoveVideo(e, seasons);
    forceUpdate();
  }

  function createSeasonElement(season /* Season */) {
    if (season === null) {
      return;
    }
    const episodsList = [];
    for (let i = 0; i < season.size(); i++) {
      const episode = season.episodes[i];

      const googleDriveURL = episode.url;
      const googleDriveKey = GoogleDriveTools.GoogleShareID(googleDriveURL) || googleDriveURL;
      episodsList.push(
        <tr>
          <td>{season.title}</td>
          <td>{episode.title}</td>
          <td>
            <img src="/assets/google-drive.svg" width={16} height={16} />{" "}
            <a className="simple-table" href={googleDriveURL} target="_blank" rel="noreferrer">
              {googleDriveKey}
            </a>
          </td>
          <td>
            <a
              className="simple-table"
              color="#FFFFFF"
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                deleteVideo(episode);
              }}
            >
              <MdDelete className="simple-table" color="white" />
            </a>
          </td>
        </tr>
      );
    }
    return <Table className="col-md-12">{episodsList}</Table>;
  }

  const seasonsElement = [];
  for (let si = 0; si < seasons.length; si++) {
    const season /* Season */ = seasons[si];
    seasonsElement.push(createSeasonElement(season));
  }
  return seasonsElement;
}
