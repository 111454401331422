export default class SearchMedia {
  constructor(catalog) {
    this.catalog = catalog;
  }

  search(searchText) {
    const re = new RegExp('(^|\\s|"|“|\\(\\[\\{)+' + searchText, "i");
    const result = this.catalog.content.filter(
      (x) => re.test(x.title) || re.test(x.sndTitle) || re.test(x.desc)
    );
    return result;
  }
}
