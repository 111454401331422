import React, { useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import getCurrentSearchParams from "../helpers/SearchParamsHelper";
import { LaCmokEvents } from "../controller/LaCmokEvents";

function CategoriesFilter(props) {
  // PROPS
  const model = props.model;
  const controller = props.controller;
  const navigate = useNavigate();
  // STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  // CONTROLLER EVENTS
  controller.addEventHandler(LaCmokEvents.ON_GENERS_READY, () => {
    setIsLoading(false);
    setList(model.getGenres());
    console.log("CategoriesFilter isReady");
  });
  // UI EVENTS
  const handleChange = (v) => {
    console.log(`CategoryFiler.handleChange = '${v}'`);
    let currentSearchParams = getCurrentSearchParams(searchParams);
    if (!v) {
      const { genre, ...updatedParams } = currentSearchParams;
      currentSearchParams = updatedParams;
    }
    if (Object.keys(currentSearchParams).length === 0) {
      navigate("../", { replace: true });
    }
    if (Object.keys(currentSearchParams).length !== 0) {
      navigate(
        {
          pathname: "../filter",
          search: `?${createSearchParams(currentSearchParams)}`,
        },
        { replace: true }
      );
    }
    if (v) {
      const updatedSearchParams = { ...currentSearchParams, genre: v.value };
      navigate(
        {
          pathname: "../filter",
          search: `?${createSearchParams(updatedSearchParams)}`,
        },
        { replace: true }
      );
    }
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 10,
      "background-color": "field",
    }),
    container: (provided, state) => ({
      ...provided,
      width: 180,
    }),
    control: (provided, state) => ({
      ...provided,
      "background-color": "field",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "inherit",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "inherit",
    }),
  };

  return (
    <Select
      className="genre-filter"
      styles={customStyles}
      value={list.find((x) => x.value === searchParams.get("genre"))}
      options={list}
      isClearable={true}
      isLoading={isLoading}
      placeholder={props.prompt}
      loadingMessage={() => {
        return props.loading;
      }}
      onChange={(v) => {
        handleChange(v);
      }}
    ></Select>
  );
}

export default CategoriesFilter;
