export class BELacinka {
  static get CYR_VOWEL() {
    return [
      "а", "о", "і", "й", "у", "ў", "ы", "э", "'",                   // eslint-disable-line
      "А", "О", "І", "Й", "У", "Ў", "Ы", "Э"                         // eslint-disable-line
    ];
  }

  static get CYR_CONSONANT() {
    return [
      "б", "в", "г", "д", "ж", "к", "м", "п", "р", "т", "ф", "х", "ч", "ш", // eslint-disable-line
      "Б", "В", "Г", "Д", "Ж", "К", "М", "П", "Р", "Т", "Ф", "Х", "Ч", "Ш"  // eslint-disable-line
    ];
  }

  static get CYR_SOFT_CONST() {
    return [
      "з", "н", "с", "ц", "л",                                  // eslint-disable-line
      "З", "Н", "С", "Ц", "Л"                                   // eslint-disable-line
    ];
  }

  static get CYR_J_VOWEL() {
    return [
      "е", "ё", "ю", "я",                                       // eslint-disable-line
      "Е", "Ё", "Ю", "Я"                                        // eslint-disable-line
    ];
  }

  // -----------------------
  // Lacinka
  // -----------------------

  static get LAT_VOWEL() {
    return [
      "a", "o", "i", "j", "u", "ŭ", "y", "e", "'",                    // eslint-disable-line
      "A", "O", "I", "J", "U", "Ū", "Y", "E"                          // eslint-disable-line
    ];
  }

  static get LAT_CONSONANT() {
    return [
      "b", "v", "h", "d", "ž", "k", "m", "p", "r", "t", "f", "ch", "č", "š", // eslint-disable-line
      "B", "V", "H", "D", "Ž", "K", "M", "P", "R", "T", "F", "CH", "Č", "Š"  // eslint-disable-line
    ];
  }

  static get LAT_HARD_CONST() {
    return [
      "z", "n", "s", "c", "ł",                                  // eslint-disable-line
      "Z", "N", "S", "C", "Ł"                                   // eslint-disable-line
    ];
  }

  static get LAT_SOFT_CONST() {
    return [
      "ź", "ń", "ś", "ć", "l",                                  // eslint-disable-line
      "Ź", "Ń", "Ś", "Ć", "L"                                   // eslint-disable-line
    ];
  }

  static get LAT_I_VAWEL() {
    return [
      "ie", "io", "iu", "ia",                                   // eslint-disable-line
      "IE", "IO", "IU", "IA"                                    // eslint-disable-line
    ];
  }

  static get LAT_J_VAWEL() {
    return [
      "je", "jo", "ju", "ja",                                   // eslint-disable-line
      "JE", "JO", "JU", "JA"                                    // eslint-disable-line
    ];
  }

  static get LAT_L_VAWEL() {
    return [
      "e", "o", "u", "a",                                       // eslint-disable-line
      "E", "O", "U", "A"                                        // eslint-disable-line
    ];
  }

  // -----------------------
  // basic latin
  // -----------------------

  static get BASIC_LAT_VOWEL() {
    return [
      "a", "o", "i", "j", "u", "u", "y", "e", "'",                    // eslint-disable-line
      "A", "O", "I", "J", "U", "U", "Y", "E"                          // eslint-disable-line
    ];
  }

  static get BASIC_LAT_CONSONANT() {
    return [
      "b", "v", "h", "d", "z", "k", "m", "p", "r", "t", "f", "ch", "c", "s", // eslint-disable-line
      "B", "V", "H", "D", "Z", "K", "M", "P", "R", "T", "F", "CH", "C", "S"  // eslint-disable-line
    ];
  }

  static get BASIC_LAT_HARD_CONST() {
    return [
      "z", "n", "s", "c", "l",                                  // eslint-disable-line
      "Z", "N", "S", "C", "L"                                   // eslint-disable-line
    ];
  }

  static isCyrVawel(peek) {
    const ind = this.CYR_VOWEL.indexOf(peek);
    return ind !== -1;
  }

  static isCyrIotVawel(peek) {
    const ind = this.CYR_J_VOWEL.indexOf(peek);
    return ind !== -1;
  }

  static isCyrConsonant(peek) {
    const ind = this.CYR_CONSONANT.indexOf(peek);
    return ind !== -1;
  }

  static isCyrSoftConsonant(peek) {
    const ind = this.CYR_SOFT_CONST.indexOf(peek);
    return ind !== -1;
  }

  static isLConsonant(peek) {
    return peek === "л" || peek === "Л";
  }

  static isSoftSign(peek) {
    return peek === "ь" || peek === "Ь";
  }

  static isCyrApostraph(peek) {
    return peek === "'";
  }

  static isLatinkaLetter(peek) {
    let ind = this.LAT_VOWEL.indexOf(peek);
    if (ind !== -1) return true;
    ind = this.LAT_CONSONANT.indexOf(peek);
    if (ind !== -1) return true;
    ind = this.LAT_HARD_CONST.indexOf(peek);
    if (ind !== -1) return true;
    ind = this.LAT_SOFT_CONST.indexOf(peek);
    if (ind !== -1) return true;
    return false;
  }

  static isLatinLetter(peek) {
    let ind = this.BASIC_LAT_VOWEL.indexOf(peek);
    if (ind !== -1) return true;
    ind = this.BASIC_LAT_CONSONANT.indexOf(peek);
    if (ind !== -1) return true;
    ind = this.BASIC_LAT_HARD_CONST.indexOf(peek);
    if (ind !== -1) return true;
    return false;
  }

  // TODO replace by map
  static latinLetter(peek, kind) {
    let ind = -1;
    switch (kind) {
      case "vowel":
        ind = this.CYR_VOWEL.indexOf(peek);
        return this.LAT_VOWEL[ind];
      case "consonant":
        ind = this.CYR_CONSONANT.indexOf(peek);
        return this.LAT_CONSONANT[ind];
      case "hard-consonant":
        ind = this.CYR_SOFT_CONST.indexOf(peek);
        return this.LAT_HARD_CONST[ind];
      case "soft-consonant":
        ind = this.CYR_SOFT_CONST.indexOf(peek);
        return this.LAT_SOFT_CONST[ind];
      case "jotated-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_J_VAWEL[ind];
      case "l-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_L_VAWEL[ind];
      case "iotated-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_I_VAWEL[ind];
      case "softsign":
      case "no-apostraph":
        return "";
      case "unknown":
      default:
        return "_";
    }
  }

  // TODO replace by map
  static baseLatinLetter(peek, kind) {
    let ind = -1;
    switch (kind) {
      case "vowel":
        ind = this.CYR_VOWEL.indexOf(peek);
        return this.BASIC_LAT_VOWEL[ind];
      case "consonant":
        ind = this.CYR_CONSONANT.indexOf(peek);
        return this.BASIC_LAT_CONSONANT[ind];
      case "hard-consonant":
        ind = this.CYR_SOFT_CONST.indexOf(peek);
        return this.BASIC_LAT_HARD_CONST[ind];
      case "soft-consonant":
        ind = this.CYR_SOFT_CONST.indexOf(peek);
        return this.BASIC_LAT_HARD_CONST[ind];
      case "jotated-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_J_VAWEL[ind];
      case "l-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_I_VAWEL[ind];
      case "iotated-vawel":
        ind = this.CYR_J_VOWEL.indexOf(peek);
        return this.LAT_I_VAWEL[ind];
      case "softsign":
      case "no-apostraph":
        return "";
      case "unknown":
      default:
        return "_";
    }
  }

  static toLatin(cyrWord, isBaseLatin = false) {
    let result = "";
    let isVawel = true;
    let isL = false;
    let isApstraph = false;
    for (let i = 0; i < cyrWord.length; i++) {
      const isEnd = i === cyrWord.length - 1;
      const peek = cyrWord.charAt(i);
      let kind = "unknown";
      if (this.isCyrApostraph(peek)) {
        kind = "vowel";
        if (!isEnd) {
          const next = cyrWord.charAt(i + 1);
          if (this.isCyrIotVawel(next)) {
            kind = "no-apostraph";
          }
        }
        isApstraph = true;
        isL = isVawel = false;
      } else if (this.isSoftSign(peek)) {
        kind = "softsign";
        isVawel = true;
        isL = isApstraph = false;
      } else if (this.isCyrVawel(peek)) {
        kind = "vowel";
        isVawel = true;
        isL = isApstraph = false;
      } else if (this.isCyrConsonant(peek)) {
        kind = "consonant";
        isL = isApstraph = isVawel = false;
      } else if (this.isLConsonant(peek)) {
        kind = "hard-consonant";
        if (!isEnd) {
          const next = cyrWord.charAt(i + 1);
          if (this.isSoftSign(next) || this.isCyrIotVawel(next)) {
            kind = "soft-consonant";
          }
        }
        isL = true;
        isVawel = isApstraph = false;
      } else if (this.isCyrSoftConsonant(peek)) {
        kind = "hard-consonant";
        if (!isEnd && this.isSoftSign(cyrWord.charAt(i + 1))) {
          kind = "soft-consonant";
        }
        isL = isApstraph = isVawel = false;
      } else if (this.isCyrIotVawel(peek)) {
        if (isVawel || isApstraph) {
          kind = "jotated-vawel";
        } else if (isL) {
          kind = "l-vawel";
        } else {
          kind = "iotated-vawel";
        }
        isVawel = true;
        isL = isApstraph = false;
      } else if (this.isLatinkaLetter(peek) && isBaseLatin === false) {
        result += peek;
        continue;
      } else if (this.isLatinLetter(peek) && isBaseLatin === true) {
        result += peek;
        continue;
      }
      if (isBaseLatin) {
        result += this.baseLatinLetter(peek, kind);
      } else {
        result += this.latinLetter(peek, kind);
      }
    }
    return result;
  }
}
