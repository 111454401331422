import { VideoQuality } from "../consts/VideoQuality";

export class LaFormat {
  static formatDuration(seconds) {
    let result = "";
    if (isNaN(seconds) || seconds === 0) {
      return result;
    }
    const min = Math.floor(seconds / 60);
    const hours = Math.floor(min / 60);
    if (hours > 0) {
      result = hours.toFixed(hours) + ":" + (min % 60).toFixed(0);
    } else {
      result = min.toFixed(0);
    }
    return result;
  }

  static getQuality(width, height) {
    let result = "";
    if (height >= 1080) {
      result = VideoQuality.FHD;
    } else if (height >= 720) {
      result = VideoQuality.HD;
    } else {
      result = VideoQuality.SD;
    }
    return result;
  }
}
