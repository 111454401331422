import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";

export function LoginPanel(props) {
  // STATE
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // CONST
  const { t } = useTranslation();
  const fbAPI = props.firebaseAPI;
  const controller = props.controller;
  const user = props.user;
  // CONTROLLER

  // FUNCTIONS
  function handleSubmit(event) {
    event.preventDefault();
    try {
      fbAPI.loginUser(username, password);
      setUsername("");
      setPassword("");
    } catch (error) {
      window.alert(error.message);
    }
  }

  function handleLogou() {
    fbAPI.logoutUser();
  }

  async function handleResetPasswrod() {
    if (!username) {
      window.alert("Missign username");
      return;
    }
    try {
      await fbAPI.sendPasswordResetEmail(username);
      window.alert("Email reset send");
    } catch (error) {
      window.alert(error.message());
    }
  }

  if (user) {
    return (
      <div className="login-form">
        <Dropdown>
          <DropdownToggle
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <MdAccountCircle /> {user.email}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <DropdownItem as="button" onClick={handleLogou}>
              {t("Logout")}
            </DropdownItem>
            <DropdownItem as="button" className="disabled">
              {t("Profile")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  } else {
    return (
      <div className="login-form-container">
        <form onSubmit={handleSubmit} className="login-form">
          <div className="mb-3">
            <label className="form-label">{t("Username (email)")}</label>
            <input
              className="form-label"
              type="email"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t("Password")}</label>
            <input
              className="form-label"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="button-box">
            <Button className="btn-primary" type="button" onClick={handleSubmit}>
              login
            </Button>
            <Button className="btn-secondary" type="button" onClick={handleResetPasswrod}>
              {t("Reset password")}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
