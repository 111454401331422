import CatalogItemKind from "../../consts/CatalogItemKind";

export class LoadCatalogCommand {
  execute(
    /* FirebaseAPI */ firebaseAPI,
    /* CatalogModel */ filmCatalog,
    /* UsersCatalogModel */ userCatalog
  ) {
    console.info("INFO: LoadCatalogCommand");
    firebaseAPI.loadContent((data) => {
      if (data.exists()) {
        console.info("INFO: data loaded from firebase");
        const c = data.val();

        userCatalog.build(c.karystalnik);

        filmCatalog.createCatalog();
        filmCatalog.buildCatalog(c.filmy, CatalogItemKind.FILM);
        filmCatalog.buildCatalog(c.seryjaly, CatalogItemKind.SERYAL);
        filmCatalog.catalogReady();
      } else {
        console.log("Firebase: Filmay niama");
      }
    });
  }
}
