import { UserData } from "../model/data/UserData";
import { FilmModel } from "../model/FilmModel";
import { LaFormat } from "../service/LaFormat";
import { JsonDataMapper } from "../service/json-mapper/JsonDataMapper";
import { UserDataMap } from "./UserDataMap";

export class JsonParser {
  static parseSize(/* Object */ data, /* String */ prop, defaultValue = [640, 480]) {
    const value = "razmer" in data && data.razmer !== undefined ? data.razmer : "640x480";
    let sizeArr = defaultValue;
    try {
      sizeArr = value.split("x");
    } catch (e) {
      console.log("WARN: JsonParse, can't parse '" + prop + "', property of FilmData");
      return defaultValue;
    }
    if (sizeArr && sizeArr.length === 2) {
      return sizeArr;
    }
    return defaultValue;
  }

  /**
   *
   * @param {string} sid identifier of video
   * @param {string} vid identifier of video
   * @return {string}
   */
  static createVideoKey(filmId, sid, vid) {
    return filmId + "." + sid + "." + vid;
  }

  static parseFilmData(/* Object */ data, /* String */ kind, /* String */ dbid) {
    if (data == null) {
      return null;
    }
    if (!("id" in data) || data.id == null) {
      return null;
    }
    const film = new FilmModel(dbid, kind);
    JsonDataMapper.fromJSON(data, film);
    // Size & Quality Format
    const size = JsonParser.parseSize(data, "razmer");
    film.videoWidth = parseInt(size[0]);
    film.videoHeight = parseInt(size[1]);
    film.quality = LaFormat.getQuality(film.videoWidth, film.videoHeight);
    // Duration Format
    film.durationText = LaFormat.formatDuration(film.duration);
    return film;
  }

  static serializeFilmData(film) {
    const result = JsonDataMapper.toJSON(film);
    result.razmer = film.videoWidth + "x" + film.videoHeight;
    return result;
  }

  static parseUserData(data) {
    const result = new UserData();
    JsonDataMapper.fromJSON(data, result, UserDataMap.PROPERTIES);
    return result;
  }
}
