import "../../pages/FilmPage.css";
import "../../pages/EditFilmPage.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Episode } from "../../model/data/Episode";
import { GoogleDriveTools } from "../../service/GoogleDriveTools";
import CatalogItemKind from "../../consts/CatalogItemKind";

export function AddEpisode({ filmId, addVideo, kind }) {
  // STATE
  const [seasonName, setSeasonName] = useState(null);
  const [episodName, setEpisodName] = useState(null);
  const [episodURL, setEpisodURL] = useState(null);
  // PROPS
  const { t } = useTranslation();

  if (kind === CatalogItemKind.FILM && seasonName !== "mova") {
    setSeasonName("mova");
  }

  function addVideoHandler() {
    const isValidEpisodeURL = GoogleDriveTools.ValidateURL(episodURL);
    const baseURL = GoogleDriveTools.GetURL(episodURL);
    const isValidEpisodeName = episodName != null && episodName !== "";
    if (!isValidEpisodeName || !isValidEpisodeURL) {
      return;
    }
    const key = filmId + "." + seasonName + "." + episodName;
    const episode = new Episode(key, episodName, baseURL + "preview");
    episode.filmId = filmId;
    episode.seasonId = seasonName;
    if (addVideo) {
      addVideo(episode);
    }
    clearState();
  }

  function clearState() {
    setSeasonName("");
    setEpisodName("");
    setEpisodURL("");
  }

  return (
    <div className="row g-3">
      <div className="col-md-3">
        <label className="form-label">Сязон</label>
        <input
          disabled={kind === CatalogItemKind.FILM}
          className="form-control"
          type="text"
          value={seasonName}
          onChange={(e) => {
            setSeasonName(e.currentTarget.value);
          }}
        />
      </div>
      <div className="col-md-3">
        <label className="form-label">Эпізод</label>
        <input
          className="form-control"
          type="text"
          value={episodName}
          onChange={(e) => {
            setEpisodName(e.currentTarget.value);
          }}
        />
      </div>
      <div className="col-md-6">
        <label className="form-label">Google Drive</label>
        <input
          className="form-control"
          type="text"
          value={episodURL}
          onChange={(e) => {
            setEpisodURL(e.currentTarget.value);
          }}
        />
      </div>
      <div className="col-md-3">
        <label className="form-label"> </label>
        <Button variant="link" onClick={addVideoHandler}>
          {t("Add")}
        </Button>
      </div>
    </div>
  );
}
