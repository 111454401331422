/**
 * This model URL to the video file all technical information about video file.
 */
export class Episode {
  constructor(key, title, url) {
    this.key = key;
    this.title = title;
    this.url = url;
  }

  /**
   * Url for the iframe which provide video
   * @type {string}
   */
  url = null;

  /**
   * Title which displayed on episod button
   * @type {string}
   */
  title = "";

  /**
   * Unique key of video which identify players wathced video and
   * progress of each video.
   * @type {string}
   */
  key = "";

  // TODO add film id and season id to build episode key on a fly.
  seasonId = null;

  filmId = null;

  getSeasonName() {
    return this.key.split(".")[1];
  }

  toString() {
    return "{" + this.key + ":" + this.url + "}";
  }
}
