import { BaseValidator } from "./BaseValidator";

export class TextValidator extends BaseValidator {
  filedName = null;
  pattern = null;

  constructor(/* String */ fieldName) {
    super();
    this.filedName = fieldName;
  }

  validate(input) {
    super.validate(input);
    if (input === null || input === "") {
      this.errors.push(`Filed '${this.filedName}' must be not empty`);
    }
  }
}
