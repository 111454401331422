import "./MemberPage.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Catalog } from "../components/Catalog";
import React, { useState } from "react";
import { Placeholder } from "react-bootstrap";
import { LaCmokEvents } from "../controller/LaCmokEvents";
import { TiStarFullOutline } from "react-icons/ti";
import { MdMonetizationOn } from "react-icons/md";

export function MemberPage(props) {
  // PROPS
  const { t } = useTranslation();
  const model = props.catalog;
  const usersModel = model.userModel;
  const controller = props.controller;
  const params = useParams();
  const memberId = params.mid;
  const memberData = model.getMemberById(memberId);
  // STATE
  const [member, setMember] = useState(memberData);
  const [isLoading, setIsLoading] = useState(!usersModel.isLoaded);
  const [catalogSize, setCatalogSize] = useState(model.getContentSize());
  const supportLinks = [];
  const socialLinks = [];
  let photo = "";
  let note = "";
  // CONTROLLER
  controller.addEventHandler(LaCmokEvents.ON_USER_READY, () => {
    const user = model.getMemberById(memberId);
    console.info("INFO: MemberPage.onUserReady()" + user);
    setMember(user);
    setIsLoading(!usersModel.isLoaded);
  });
  controller.addEventHandler(LaCmokEvents.ON_GENERS_READY, () => {
    setCatalogSize(model.getContentSize());
  });

  note = t("memberNotFound", { memberId });
  photo = "/assets/memberNoPhoto.png";
  let memberElement = (
    <div className="member-block">
      <div className="member-photo" style={{ backgroundColor: "#FFFFFF" }}>
        <img width={64} height={64} src={photo} />
      </div>
      <div className="member-details">
        <div className="member-title-name">{memberId}</div>
        <div className="member-star">
          <TiStarFullOutline size={18} color="#000000" /> {catalogSize}
        </div>
        <div className="member-note">{note}</div>
      </div>
    </div>
  );
  if (isLoading) {
    memberElement = (
      <div className="member-block">
        <div className="member-photo">
          <img width={128} height={128} src={photo} />
        </div>
        <div className="member-details">
          <div className="member-title">{t("content-from", { nickname: memberId })}</div>
          <Placeholder xs={7} animation="glow" />
          <Placeholder xs={7} animation="glow" /> <Placeholder xs={7} animation="glow" />{" "}
          <Placeholder xs={7} animation="glow" />
        </div>
      </div>
    );
  } else if (member) {
    note = member.note;
    photo = member.photo || "/assets/memberNoPhoto.png";
    if (member.hasSocialLinks()) {
      const links = member.getSocialLinks();
      for (let i = 0; i < links.length; i++) {
        if (i !== 0) {
          socialLinks.push(<span className="social-link-pipe">&nbsp;|&nbsp;</span>);
        }
        const linkType = links[i][0];
        const linkURL = links[i][1];
        socialLinks.push(
          <a className="social-link" href={linkURL} target="_blank" rel="noreferrer" key={linkType}>
            {linkType}
          </a>
        );
      }
    }
    if (member.patreon) {
      supportLinks.push(
        <a
          className="support-link"
          href={member.patreon}
          target="_blank"
          rel="noreferrer"
          key="tel_link"
        >
          <img src="/assets/patreon.svg" width={32} height={32} />
        </a>
      );
    }
    if (member.donationalerts) {
      supportLinks.push(
        <a
          className="support-link"
          href={member.donationalerts}
          target="_blank"
          rel="noreferrer"
          key="tel_link"
        >
          <MdMonetizationOn size={32} />
        </a>
      );
    }
    memberElement = (
      <div className="member-block">
        <div className="member-photo">
          <img width={64} height={64} src={photo} />
        </div>
        <div className="member-details">
          <div className="member-title">
            <div className="member-title-name">{memberId}</div>
            <div className="member-star">
              <TiStarFullOutline size={18} color="#000000" /> {catalogSize}
            </div>
          </div>
          <div className="member-note">{socialLinks}</div>
        </div>
        {supportLinks && supportLinks.length > 0 ? (
          <div className="support-block">
            <div className="member-links-caption">{t("support-member")}</div>
            <div className="member-links">{supportLinks}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  model.filterByUser(memberId);

  return (
    <>
      {memberElement}
      <Catalog t={t} model={model} controller={controller} fbApi={props.fbApi} />
    </>
  );
}
