import { initializeApp } from "firebase/app";
import { child, get, getDatabase, push, ref, set } from "firebase/database";
import { getDownloadURL, getStorage, ref as sref, uploadBytes } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import CatalogItemKind from "../consts/CatalogItemKind.js";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { UserData } from "../model/data/UserData";
import { LaCmokEvents } from "../controller/LaCmokEvents";

let firebaseConfig;
if (process.env.NODE_ENV === "development") {
  firebaseConfig = {
    apiKey: "AIzaSyA9s5cKDB3jsRCoV18rsQxcRmyrh_X1Ko4",
    authDomain: "lahodny-cmok-dev.firebaseapp.com",
    databaseURL: "https://lahodny-cmok-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lahodny-cmok-dev",
    storageBucket: "lahodny-cmok-dev.appspot.com",
    messagingSenderId: "253108035610",
    appId: "1:253108035610:web:56f7d7e80a096da91db1a3",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBcD2OtpzjL5Bpl_8ompH2n0aDbMlEGGrE",
    authDomain: "lahodny-cmok.firebaseapp.com",
    databaseURL: "https://lahodny-cmok-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lahodny-cmok",
    storageBucket: "lahodny-cmok.appspot.com",
    messagingSenderId: "683890686886",
    appId: "1:683890686886:web:1fca89d18e20b5f95d6d67",
    measurementId: "G-RPY6MEZKF9",
  };
}

export class FirebaseAPI {
  cache = null;
  auth = null;

  constructor(/* LaCmokEventDispatcher */ controller, cache) {
    this.cache = cache;
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
    this.storage = getStorage();
    this.db = getDatabase();
    this.dbRef = ref(getDatabase());
    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      controller.dispatchEvent(LaCmokEvents.ON_AUTH_CHANGED);
    });
  }

  // ----------------------------
  // S
  // ----------------------------

  // ----------------------------
  // AUTH API
  // ----------------------------

  currentUser() {
    const fbUser = getAuth().currentUser;
    if (fbUser == null) {
      return null;
    }
    return new UserData("", "", "", "", "", "", "", "", "", "", fbUser.email);
  }

  registerUser(email, password) {
    const result = createUserWithEmailAndPassword(this.auth, email, password);
    return result;
  }

  loginUser(email, password) {
    const result = signInWithEmailAndPassword(this.auth, email, password);
    return result;
  }

  loginWithGoogle() {
    const provider = this.auth.GoogleAuthProvider;
    const result = signInWithPopup(this.auth, provider);
    return result;
  }

  logoutUser() {
    const result = signOut(this.auth);
    return result;
  }

  async sendPasswordResetEmail(email) {
    const result = await sendPasswordResetEmail(this.auth, email);
    return result;
  }

  // -----------------------------
  // Analytic
  // -----------------------------

  logMovie(/* FilmModel */ film) {
    logEvent(this.analytics, "view_film", {
      title: film.title,
      kind: film.kind,
      genre: film.genre,
      lang: film.lang,
    });
  }

  // -----------------------------
  // Storage
  // -----------------------------

  loadContent(successCallback) {
    get(child(this.dbRef, `content`))
      .then(successCallback)
      .catch((error) => {
        console.error(error);
      });
  }

  addFilm(/* FilmModel */ data) {
    const filmy = child(this.dbRef, "content/filmy");
    const newItem = push(filmy);
    const result = set(newItem, data);
    return result;
  }

  updateFilm(/* FilmModel */ data, /* String */ dbid) {
    const filmy = child(this.dbRef, "content/filmy/" + dbid);
    const result = set(filmy, data);
    return result;
  }

  updatePoster(filmId, kind, bitmapData, onLoad) {
    if (filmId === null || filmId === "") {
      throw "FirebaseAPI.updatePoster: Film id Must be defined";
    }
    if (bitmapData === null) {
      throw "FirebaseAPI.updatePoster: bitmapData must be defined";
    }
    const sKind = kind === CatalogItemKind.FILM ? "filmy" : "seryaly";
    const path = `content/${sKind}/${filmId}/thumbnail400.jpg`;
    const dir = sref(this.storage, path);
    uploadBytes(dir, bitmapData).then((snapshot) => {
      if (onLoad) onLoad();
    });
  }

  deletePoster(url) {
    const decodedUrl = decodeURIComponent(url);
    const startIndex = decodedUrl.indexOf("/o/") + 3;
    const endIndex = decodedUrl.indexOf("?");
    const filePath = decodedUrl.substring(startIndex, endIndex);
    return this.storage.ref().child(filePath).delete();
  }

  getPosterUrl(filmId /* String */, kind /* String */, onload, onerror) {
    const kindUrl = kind === CatalogItemKind.FILM ? "filmy" : "seryaly";
    const key = `content/${kindUrl}/${filmId}/thumbnail400.jpg`;
    const url = this.getCache(key);
    if (url) {
      if (onload) onload(url);
    }
    const laPoster = sref(this.storage, key);
    getDownloadURL(laPoster)
      .then((url) => {
        this.setCache(key, url);
        if (onload) onload(url);
      })
      .catch((error) => {
        this.setCache(key, "/assets/NoPoster.jpg");
        if (onerror) onerror(error);
      });
  }

  // -----------------------------
  // Cache
  // -----------------------------

  getCache(key) {
    let result = null;
    if (this.cache != null) {
      result = this.cache.getItem(key);
      // console.info(`INFO: FirebaseAPI.getCache, ${key} = ${result}`);
    }
    return result;
  }

  setCache(key, value) {
    if (this.cache != null) {
      // console.info(`INFO: FirebaseAPI.setCache, ${key} = ${value}`);
      this.cache.setItem(key, value);
    }
    return value;
  }
}
