import { HTTPRequest } from "./HTTPRequest";

export class TMDBAPI {
  tmdbConfig = {
    imageBaseURL: "https://image.tmdb.org/t/p",
    posterWidth: "w342",
    apiBaseURL: "https://api.themoviedb.org/3",
    apiKey: "c2676413319d89e7fbfb9ad71d43c1db",
  };

  getPosterUrl(path) {
    const conf = this.tmdbConfig;
    const url = `${conf.imageBaseURL}/${conf.posterWidth}/${path}`;
    return url;
  }

  getTVInfo(imdbId, onload, onerror) {
    if (imdbId == null) {
      throw "TMDBAPI.getTVInfo: imdb can not be null";
    }
    const conf = this.tmdbConfig;
    const url = `${conf.apiBaseURL}/tv/${imdbId}?api_key=${conf.apiKey}`;
    this.send(url, onload, onerror);
  }

  getMovieInfo(imdbId, onload, onerror) {
    if (imdbId == null) {
      throw "TMDBAPI.getMovieInfo: imdbId can not be null";
    }
    const conf = this.tmdbConfig;
    const url = `${conf.apiBaseURL}/movie/${imdbId}?api_key=${conf.apiKey}`;
    this.send(url, onload, onerror);
  }

  send(url, onload, onerror) {
    const request = new HTTPRequest(url);
    request.getURL(
      null,
      (data) => {
        console.log(data);
        if (onload) onload(data);
      },
      (error) => {
        console.log(error);
        if (onerror) onerror(error);
      }
    );
  }

  get GENERS_MAP() {
    return [
      { id: 28, name: "Action", lacmok: "act" },
      { id: 12, name: "Adventure", lacmok: "adv" },
      { id: 16, name: "Animation", lacmok: "anim" },
      { id: 35, name: "Comedy", lacmok: "c" },
      { id: 80, name: "Crime", lacmok: "crime" },
      { id: 99, name: "Documentary", lacmok: "doc" },
      { id: 18, name: "Drama", lacmok: "d" },
      { id: 10751, name: "Family", lacmok: "f" },
      { id: 14, name: "Fantasy", lacmok: "fantasy" },
      { id: 36, name: "History", lacmok: "history" },
      { id: 27, name: "Horror", lacmok: "h" },
      { id: 10402, name: "Music", lacmok: "music" },
      { id: 9648, name: "Mystery", lacmok: "m" },
      { id: 10749, name: "Romance", lacmok: "r" },
      { id: 878, name: "Science Fiction", lacmok: "sf" },
      { id: 10770, name: "TV Movie" },
      { id: 53, name: "Thriller", lacmok: "t" },
      { id: 10752, name: "War", lacmok: "war" },
      { id: 37, name: "Western", lacmok: "w" },
    ];
  }

  findTMDBGener(tmdbGener) {
    if (tmdbGener == null) {
      return null;
    }
    const MAP = this.GENERS_MAP;
    for (let i = 0; i < MAP.length; i++) {
      if (MAP[i].id === tmdbGener.id) {
        return MAP[i];
      }
    }
  }

  tmdbToLacmokGeners(tmdbGeners) {
    const result = [];
    for (let i = 0; i < tmdbGeners.length; i++) {
      const gener = tmdbGeners[i];
      const mapObject = this.findTMDBGener(gener);
      if (mapObject === null) {
        console.log("TMDBDataTransformer.tmdbToLacmokGeners: ");
        continue;
      }
      result.push([mapObject.lacmok]);
    }
    return result;
  }
}
