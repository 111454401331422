import React from "react";
import { createRoot } from "react-dom/client";
import "./service/i18n.js";
import "./api/FirebaseAPI.js";
import "bootswatch/dist/cyborg/bootstrap.min.css";
import App from "./components/App";

import { BrowserRouter } from "react-router-dom";
import { LaCmokDependencyManager } from "./LaCmokDependencyManager";
import { LaCmokEvents } from "./controller/LaCmokEvents";

const cmokDM = new LaCmokDependencyManager();
cmokDM.controller.dispatchEvent(LaCmokEvents.LOAD_CATALOG);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App
        tab="home"
        catalogController={cmokDM.controller}
        catalog={cmokDM.catalogModel}
        userModel={cmokDM.userModel}
        firebaseAPI={cmokDM.fbAPI}
        tmdbAPI={cmokDM.tmdbAPI}
      />
    </BrowserRouter>
  </React.StrictMode>
);
