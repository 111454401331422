export class ParserError {
  constructor(row, col, message) {
    this.row = row;
    this.col = col;
    this.message = message;
  }

  toString() {
    return this.row + ":" + this.col + " " + this.message;
  }
}
