import React, { useState } from "react";
import "./LaForm.css";

export function LaInput(props) {
  // STATE
  const [value, setValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // PROPS
  const label = props.label;
  const validator = props.validator;
  const onChange = props.onChange;
  const hasValue = value !== null;
  const initialValue = props.value;
  const hasInitialValue = props.value != null && props.value !== "" && props.value !== "!EMPTY!";
  const isClear = initialValue === "!EMPTY!";
  const highlightChanges = props.highlightChanges;
  const hasChanges = hasValue && hasInitialValue && value !== initialValue;
  if (isClear && hasValue) {
    clear();
  } else if (!hasValue && hasInitialValue) {
    setValue(initialValue);
  }

  // UI Event Handler
  function handleInputChagne(e) {
    const newValue = e.target.value;
    const isClear = newValue === "!EMPTY!";
    if (isClear) {
      clear();
      return;
    }
    validate(newValue);
    setValue(newValue);
    if (onChange && !errorMessage) {
      onChange(newValue);
    }
  }

  function handleBlur(e) {
    validate();
  }

  function clear() {
    setErrorMessage(null);
    setValue(null);
  }

  function validate(newValue) {
    if (validator) {
      validator.validate(newValue);
      const isValid = validator.isValid();
      if (isValid) {
        setErrorMessage(null);
      } else if (errorMessage !== validator.getErrorMessage()) {
        setErrorMessage(validator.getErrorMessage());
      }
    }
  }

  return (
    <div className={props.className}>
      <label className="form-label">{label}</label>
      <input
        disabled={props.disabled}
        className={highlightChanges && hasChanges ? "form-control changed" : "form-control"}
        type="text"
        value={value}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onChange={(e) => {
          handleInputChagne(e);
        }}
      />
      {errorMessage && (
        <p role="alert" style={{ color: "rgb(255, 0, 0)" }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
}
