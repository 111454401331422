export class UserDataMap {
  static get PROPERTIES() {
    return [
      // JSON,   JSON_TYPE,    OBJ,      OBJ_TYPE, DEFAULT, MONDATORY
      ["imja", "s", "nickname", "s", "", true],
      ["avatar", "s", "photo", "s", "", false],
      ["apisanne", "s", "note", "s", "", false],
      ["telegram", "s", "telegram", "s", "", false],
      ["patreon", "s", "patreon", "s", "", false],
      ["youtube", "s", "youtube", "s", "", false],
      ["instagram", "s", "instagram", "s", "", false],
      ["twitter", "s", "twitter", "s", "", false],
      ["tiktok", "s", "tiktok", "s", "", false],
      ["donationalerts", "s", "donationalerts", "s", "", false],
    ];
  }
}
