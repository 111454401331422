export class CatalogFilterData {
  /**
   * filter fields
   */
  kind = "";
  search = "";
  genre = "";
  lang = "";
  user = "";

  /**
   * filter all films which is not published.
   * @type {boolean}
   */
  published = true;

  /**
   * valid filter applied and cached
   */
  valid = false;
}
