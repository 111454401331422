import { NLTokenType } from "./NLTokenType";

export class NLToken {
  kind = 0;
  start = -1;
  end = -1;
  value = "";

  constructor(kind = 0, start = -1, end = -1) {
    this.kind = kind;
    this.start = start;
    this.end = end;
  }

  toString() {
    let result = "";
    switch (this.kind) {
      case NLTokenType.END_OF_PHRASE:
        result += "|";
        break;
      case NLTokenType.UNDEFINED:
        result += "<!>";
        break;
      case NLTokenType.TIME:
        result += "<T>";
        break;
      case NLTokenType.DATE:
        result += "<D>";
        break;
      case NLTokenType.T_SPACE:
        result += " ";
        break;
      case NLTokenType.WORD:
        result += "[W" + this.value + "]";
        break;
      case NLTokenType.INT:
        result += "[I" + this.value + "]";
        break;
      case NLTokenType.NUM:
        result += "[N" + this.value + "]";
        break;
      case NLTokenType.T_SKIP:
        result += "#" + this.value.codePointAt(0);
        break;
      case NLTokenType.PUNCTUATION:
        result += "PC";
        break;
      default:
        result += "U";
        break;
    }
    return result;
  }
}
