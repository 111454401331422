export class Char {
  static get SKIP() {
    return [
      8482, // (TM)
      129303,
      127808,
    ];
  }

  static get PARENTHESIS() {
    return [40, 41];
  }

  static get CH_LEFT_PARENTHESIS() {
    return 40;
  }

  static get CH_RIGHT_PARENTHESIS() {
    return 41;
  }

  static get SPECIAL() {
    return [91, 93, 123, 125, 95, 35, 36, 93, 94, 64, 126, 124, 38];
  }

  // [, ]
  static get CH_SQB_OPEN() {
    return 91;
  }

  static get CH_SQB_CLOSE() {
    return 93;
  }

  // {, }
  static get CH_CURLY_OPEN() {
    return 123;
  }

  static get CH_CURLY_CLOSE() {
    return 125;
  }

  // #
  static get CH_NUMBER_SIGN() {
    return 35;
  }

  // _
  static get UNDERSCORE() {
    return 95;
  }

  // $
  static get CH_DOLLAR_SIGN() {
    return 36;
  }

  // \
  static get CH_BACKSLASH() {
    return 93;
  }

  // ^
  static get CH_CARAT() {
    return 94;
  }

  // @
  static get CH_AT() {
    return 64;
  }

  // ~
  static get CH_TILDE() {
    return 126;
  }

  // |
  static get CH_PIPELINE() {
    return 124;
  }

  // &
  static get CH_AMPERSAND() {
    return 38;
  }

  // MATH +, =, <, >, %, /,
  static get MATH() {
    return [43, 61, 60, 62, 37, 47, 42, this.CH_MINUS_SIGN];
  }

  static get CH_PLUS_SIGN() {
    return 43;
  }

  static get CH_EQ() {
    return 61;
  }

  static get CH_LT() {
    return 60;
  }

  static get CH_GT() {
    return 62;
  }

  static get CH_PERCENT_SIGN() {
    return 37;
  }

  static get CH_FORWARD_SLASH() {
    return 47;
  }

  static get CH_ASTERISK() {
    return 42;
  }

  // END OF PHRASE
  static get EOP() {
    return [44, 46, 33, 63];
  }

  static get CH_COMMA() {
    return 44;
  }

  static get CH_DOT() {
    return 46;
  }

  static get CH_EXCLAMATION_MARK() {
    return 33;
  }

  static get CH_QUESTION_MARK() {
    return 63;
  }

  // PUNCTIATION : ; , . ! ? " … ¿
  static get PUNCTUATION() {
    return [58, 59, 34, 8217, this.CH_MINUS_SIGN, this.APOSTROPHE, this.CH_ACCENT];
  }

  static get CH_COLON() {
    return 58;
  }

  static get CH_SEMICOLON() {
    return 59;
  }

  static get QUOTATION() {
    return 34;
  }

  static get CH_DOTS() {
    return 8217;
  }

  static get CH_BEGINING_QUESTION() {
    return 191;
  }

  // WHITE SPACE
  static get WHITESPACE() {
    return [32, 160, 9, 10, 11, 12, 13, 133];
  }

  static get SPACE() {
    return 32;
  }

  static get NO_BRAKABLE_SPACE() {
    return 160;
  }

  static get CHAR_TAB() {
    return 9;
  }

  static get LINE_END() {
    return 10;
  }

  static get LINE_TAB() {
    return 11;
  }

  static get FORM_FEED() {
    return 12;
  }

  static get CH_CARRIAGE_RETURN() {
    return 13;
  }

  static get NEXT_LINE() {
    return 133;
  }

  // NUMBER PARTS - .
  // DIGITS
  static get DIGITS() {
    return [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  }

  static get CH_NUM_0() {
    return 48;
  }

  static get CH_NUM_1() {
    return 49;
  }

  static get CH_NUM_2() {
    return 50;
  }

  static get CH_NUM_3() {
    return 51;
  }

  static get CH_NUM_4() {
    return 52;
  }

  static get CH_NUM_5() {
    return 53;
  }

  static get CH_NUM_6() {
    return 54;
  }

  static get CH_NUM_7() {
    return 55;
  }

  static get CH_NUM_8() {
    return 56;
  }

  static get CH_NUM_9() {
    return 57;
  }

  // WORD PARTS (' - ` &)
  // '
  static get APOSTROPHE() {
    return 39;
  }

  // - Minus and Hyphen in words
  static get CH_MINUS_SIGN() {
    return 45;
  }

  // `
  static get CH_ACCENT() {
    return 96;
  }

  // LETTERS
  static get CH_UPPER_A() {
    return 65;
  }

  static get CH_UPPER_C() {
    return 67;
  }

  static get CH_UPPER_F() {
    return 70;
  }

  static get CH_UPPER_M() {
    return 77;
  }

  static get CH_UPPER_T() {
    return 84;
  }

  static get CH_UPPER_Z() {
    return 90;
  }

  static get CH_LOW_A() {
    return 97;
  }

  static get CH_LOW_C() {
    return 99;
  }

  static get CH_LOW_F() {
    return 102;
  }

  static get CH_LOW_M() {
    return 109;
  }

  static get CH_LOW_T() {
    return 116;
  }

  static get CH_LOW_Z() {
    return 122;
  }
}
