import React from "react";
import { FilmRepeater } from "./shared/FilmRepeater";
import { useTranslation } from "react-i18next";
import "./Category.css";

export function SimpleGroup({ films, name, hint, fbApi }) {
  const { t } = useTranslation();
  return (
    <div className="category">
      {name ? <div className="category-title">{name}</div> : ""}
      <div className="category-wrapper">
        <FilmRepeater images={films} showTitle={true} empty={t(hint)} fbApi={fbApi} />
      </div>
    </div>
  );
}
