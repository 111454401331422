export class MediaContentRating {
  static get A0() {
    return "0+";
  }

  static get A6() {
    return "6+";
  }

  static get A12() {
    return "12+";
  }

  static get A16() {
    return "16+";
  }

  static get A18() {
    return "18+";
  }

  static get VALUES() {
    return [this.A0, this.A6, this.A12, this.A18, this.A18];
  }
}
