import { SimpleGroup } from "./SimpleGroup.js";
import React, { useState } from "react";
import CatalogItemKind from "../consts/CatalogItemKind.js";
import CatalogGroups from "../consts/CatalogGroups";
import { Helmet } from "react-helmet";
import { LaCmokEvents } from "../controller/LaCmokEvents";
import { PageLoader } from "./shared/PageLoader";
import { useTranslation } from "react-i18next";

export function Catalog(props) {
  // PROPS
  const model = props.model;
  const controller = props.controller;
  const fbApi = props.fbApi;
  const { t } = useTranslation();
  // STATE
  const [groups, setGroups] = useState(model.getCategories());
  const [isLoading, setIsLoading] = useState(!model.isLoaded);
  // handlers
  controller.addEventHandler(LaCmokEvents.ON_CATALOG_READY, () => {
    setGroups(model.getCategories());
    setIsLoading(!model.isLoaded);
    console.log("Catalog onCatalogReady handler");
  });
  controller.addEventHandler(LaCmokEvents.ON_GENERS_READY, () => {
    setGroups(model.getCategories());
    setIsLoading(!model.isLoaded);
    console.log("Catalog onCatalogReady handler");
  });

  const hasGenreFilter = model.isGenreFilter();
  const hasLangFilter = model.isLangFilter();
  const hasUserFilter = model.isUserFilter();
  const hasComplexFilter = model.isComplexFilter();
  console.info("Catalog.render: " + groups);

  if (isLoading) {
    return <PageLoader />;
  }

  if (hasGenreFilter || hasLangFilter || hasUserFilter) {
    let groupTitle = "";
    if (hasUserFilter) {
      groupTitle = "";
    } else {
      const dub = model.isLangFilter();
      switch (model.catalogFilter.kind) {
        case CatalogItemKind.FILM:
          groupTitle = dub ? "ahuchanya-filmy" : "use-filmy";
          break;
        case CatalogItemKind.SERYAL:
          groupTitle = dub ? "ahuchanya-seryaly" : "use-seryaly";
          break;
        default:
          groupTitle = dub ? "ahuchanye" : "use-filmy-i-seryaly";
          break;
      }
      groupTitle = t(groupTitle);
      if (model.isGenreFilter()) {
        const key = "pa-zanru";
        const generName = t(props.model.catalogFilter.genre);
        groupTitle = groupTitle + t(key, { zanr: generName });
      }
    }
    return (
      <div className="categories">
        <SimpleGroup
          key="fil"
          name={groupTitle}
          films={model.filteredContent}
          hint="Niсoha nia znojdziena"
          fbApi={fbApi}
        />
      </div>
    );
  }
  const g = [];
  const groupIds = [
    CatalogGroups.UNPUBLISH,
    CatalogGroups.NEW,
    CatalogGroups.BEL,
    CatalogGroups.REST,
  ];
  for (let i = 0; i < groupIds.length; i++) {
    const cid = groupIds[i];
    if (cid in groups) {
      g.push(
        <SimpleGroup
          key={cid}
          name={t(cid)}
          films={groups[cid]}
          hint="Niсoha nia znojdziena"
          fbApi={fbApi}
        />
      );
    }
  }
  if (g.length) {
    return (
      <div className="categories">
        <Helmet>
          <title>{t("site-title")}</title>
          <meta name="description" content={t("site-desc")} />
        </Helmet>
        {g}
      </div>
    );
  }
  return (
    <div className="categories">
      <Helmet>
        <title>{t("site-title")}</title>
        <meta name="description" content={t("site-desc")} />
      </Helmet>
      {t("fimlmau niama")}
    </div>
  );
}
