import React from "react";
import "./Footer.css";
import { APP_VERSION } from "../version";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function Footer(props) {
  // CONST
  const { t } = props;
  // RENDER
  return (
    <Container className="footer">
      <Row className="footer-copyright">
        <div></div>
      </Row>
      <Row>
        <div>
          <a href="https://t.me/la_cmok" className="footer-social-link">
            <img src="/assets/telegram.svg" width={32} height={32} />
          </a>
          <a
            href="https://www.patreon.com/bePatron?u=76041191"
            data-patreon-widget-type="become-patron-button"
            className="footer-social-link"
          >
            <img src="/assets/patreon.svg" width={32} height={32} />
          </a>
          <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
        </div>
        <div>
          <a className="link-padding" href="https://t.me/la_cmok_bot">
            {t("Zviazacca z kamandaj")}
          </a>{" "}
          | <Link to="/about">Пра нас</Link>
        </div>
      </Row>
      <Row>
        <Col className="footer-copyright-name">© Лагодны Цмок, v{APP_VERSION}</Col>
      </Row>
    </Container>
  );
}

export default Footer;
