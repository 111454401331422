import React from "react";
import { DebounceInput } from "react-debounce-input";
import SearchMedia from "../helpers/SearchMedia";
import { Link } from "react-router-dom";

import "./Search.css";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      displayList: "none",
    };
    this.controller = props.catalogController;
    this.SearchClass = new SearchMedia(props.model);
    this.list = [];
    this.ref = React.createRef();
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  search(value) {
    const minLength = this.props.minLength || 3;
    if (value.length < minLength) return;
    const filtered = this.SearchClass.search(value);
    if (filtered.length === 0) {
      this.setState({ filtered: [], displayList: "none" });
      return;
    }
    const resultList = [];
    for (let i = 0; i < filtered.length; i++) {
      resultList.push(
        <Link key={i} to={"/film/" + filtered[i].id}>
          <li>{filtered[i].title ? filtered[i].title : filtered[i].id}</li>
        </Link>
      );
    }
    this.list = resultList;
    this.setState({ filtered, displayList: "initial" });
  }

  clickedOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.state.displayList === "initial" && this.setState({ displayList: "none" });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.clickedOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickedOutside, true);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="search-field">
        <DebounceInput
          inputRef={this.ref}
          placeholder={t("posuk")}
          minLength={3}
          debounceTimeout={300}
          forceNotifyOnBlur={true}
          onChange={(e) => this.search(e.target.value)}
          onClick={() => {
            this.state.filtered.length && this.setState({ displayList: "initial" });
          }}></DebounceInput>
        <SearchList searchOutput={this.list} display={this.state.displayList} />
      </div>
    );
  }
}

function SearchList(props) {
  return (
    <ul className="list" style={{ display: props.display }}>
      {props.searchOutput}
    </ul>
  );
}

export default Search;
