import { CatalogFilterData } from "./data/CatalogFilterData.js";
import i18n from "../service/i18n.js";
import CatalogItemKind from "../consts/CatalogItemKind.js";
import CatalogGroups from "../consts/CatalogGroups";
import LangCodes from "../consts/LangCodes";
import { JsonParser } from "../api/JsonParsers";
import { LaCmokEvents } from "../controller/LaCmokEvents";

/**
 * Catalog Model handle all the model loaded from DB and provide API to use it on
 * client side.
 *
 * Catalog available from the early stages of APP LifeCycle but it's content could
 * be not ready in beginning.
 */
export class CatalogModel {
  // MODEL
  controller = null;
  api = null;
  userModel = null;
  // FIELDS
  filteredContent = [];
  filteredCategories = {};
  content = [];
  contentById = {};
  contentByGenre = {};
  genresList = [];
  isLoaded = false;

  // CONSTRUCTOR
  constructor(c, api, userModel) {
    this.controller = c;
    this.api = api;
    this.catalogFilter = new CatalogFilterData();
    this.userModel = userModel;
    this.createCatalog();
  }

  getFilmById(id) {
    let result = null;
    if (id !== null && this.contentById && id in this.contentById) {
      result = this.contentById[id];
    }
    return result;
  }

  getMemberById(memberId) {
    return this.userModel.getMemberById(memberId);
  }

  createCatalog() {
    this.filteredContent = [];
    this.filteredCategories = {};
    this.content = [];
    this.contentById = {};
    this.contentByGenre = {};
    this.genresList = [];
    this.totalFilmsCount = 0;
    this.isLoaded = false;
  }

  /**
   * @param jsonData
   */
  buildCatalog(/* Array */ jsonData, type) {
    for (const dbid in jsonData) {
      const filmData = jsonData[dbid];
      const film = JsonParser.parseFilmData(filmData, type, dbid);
      if (film == null) {
        console.warn("WARN: Invalid " + dbid + " data: " + JSON.stringify(filmData));
        continue;
      }
      film.create(this.controller);
      this.addFilm(film);
    }
    this.totalFilmsCount = this.content.length;
  }

  /**
   * Update catalog
   * @param film
   */
  addFilm(film) {
    if (film.id == null) {
      console.warn("WARN: CatalogModel.addFilm with id == null");
      return;
    }
    if (film.id in this.contentById) {
      console.warn(`ERROR: CatalogModel.addFilm duplicate film id ${film.id}`);
      return;
    }
    this.content.push(film);
    this.contentById[film.id] = film;
    for (let categoryIndex = 0; categoryIndex < film.genre.length; categoryIndex++) {
      const categoryId = film.genre[categoryIndex];
      this.addFilmToCategory(categoryId, film);
    }
  }

  /**
   *
   * @param {FilmModel} film
   */
  updateFilm(film) {
    const outdate = this.getFilmById(film.id);
    const outdateIndex = this.content.indexOf(outdate);
    this.content.slice(outdateIndex, outdateIndex + 1);
    this.content.push(film);
    this.contentById[film.id] = film;
    for (let categoryIndex = 0; categoryIndex < film.genre.length; categoryIndex++) {
      const categoryId = film.genre[categoryIndex];
      this.addFilmToCategory(categoryId, film);
    }
  }

  catalogReady() {
    console.info(`INFO: CatalogModel.catalogReady with '${this.totalFilmsCount}' films`);
    this.isLoaded = true;
    this.controller.dispatchEvent(LaCmokEvents.ON_GENERS_READY);
    this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
  }

  isComplexFilter() {
    const isKindFilter = this.isKindFilter();
    const isGenreFilter = this.isGenreFilter();
    const isLangFilter = this.isLangFilter();
    return isGenreFilter || isLangFilter;
  }

  isPublishedFilter() {
    return this.catalogFilter.published === true;
  }

  isGenreFilter() {
    const g = this.catalogFilter.genre;
    return g !== undefined && g !== null && g !== "";
  }

  isLangFilter() {
    const l = this.catalogFilter.lang;
    return l !== undefined && l !== null && l !== "";
  }

  isKindFilter() {
    const k = this.catalogFilter.kind;
    return k !== undefined && k !== null && k !== "" && k !== CatalogItemKind.USE;
  }

  isUserFilter() {
    const u = this.catalogFilter.user;
    return u !== undefined && u !== null && u !== "";
  }

  isSearchFilter() {
    const s = this.catalogFilter.search;
    return s !== undefined && s !== null && s !== "" && s.length > 2;
  }

  filterByKind(kind) {
    if (this.catalogFilter.kind !== kind) {
      this.catalogFilter.kind = kind;
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  filterByLang(lang) {
    if (this.catalogFilter.lang !== lang) {
      this.catalogFilter.lang = lang;
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  filterByUser(user) {
    if (this.catalogFilter.user !== user) {
      this.catalogFilter.user = user;
      this.catalogFilter.lang = "";
      this.catalogFilter.genre = "";
      this.catalogFilter.kind = "";
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  /**
   * Filter content by genre parameter.
   *
   * @param gener
   */
  filterByGener(gener) {
    if (this.catalogFilter.genre !== gener) {
      this.catalogFilter.genre = gener;
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  filterByPublished(/* Boolean */ published) {
    if (this.catalogFilter.published !== published) {
      this.catalogFilter.published = published;
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  /**
   * Filter content by search request.
   *
   * @param search
   */
  filterBySearch(search) {
    if (this.catalogFilter.search !== search) {
      this.catalogFilter.search = search;
      this.catalogFilter.valid = false;
      if (this.isLoaded) {
        this.controller.dispatchEvent(LaCmokEvents.ON_CATALOG_READY);
      }
    }
  }

  applyFilter() {
    const log = "CatalogModel.applayFiler, ";
    if (!this.isLoaded) {
      console.log(log, " catalog is not loaded");
      return;
    }
    if (this.filteredContent.valid) {
      console.log(log, " still actual");
      return;
    }
    this.filteredContent = [];
    for (let i = 0; i < this.totalFilmsCount; i++) {
      const item = this.content[i];
      //
      if (this.isPublishedFilter() && item.display === false) {
        continue;
      }
      // skip if user defined by not matched
      if (this.isUserFilter() && !item.hasUser(this.catalogFilter.user)) {
        continue;
      }
      // skip different kind
      if (this.isKindFilter() && item.kind !== this.catalogFilter.kind) {
        continue;
      }

      // skip if filtered by language but don't match the filter condition
      if (this.isLangFilter() && item.lang.indexOf(this.catalogFilter.lang) === -1) {
        continue;
      }

      // skip mismatch in genre
      if (this.isGenreFilter() && item.genre.indexOf(this.catalogFilter.genre) === -1) {
        continue;
      }
      // skip mismatch in search
      // TODO: Move search logic

      this.filteredContent.push(item);
    }
    console.log(log + ` ${this.filteredContent.length} items`);
    this.splitFilmsByGroups();
    this.catalogFilter.valid = true;
  }

  getGenres() {
    return this.genresList;
  }

  getCategories() {
    this.applyFilter();
    return this.filteredCategories;
  }

  getContent() {
    this.applyFilter();
    return this.filteredContent;
  }

  getContentSize() {
    if (this.filteredContent) {
      return this.filteredContent.length;
    }
    return 0;
  }

  // ---------------
  // Private
  // ---------------

  splitFilmsByGroups() {
    this.filteredContent = this.filteredContent.sort((a, b) => {
      const at = a.updated.getTime();
      const bt = b.updated.getTime();
      return bt - at;
    });

    // Create category belarussian and otehrs
    this.filteredCategories = {};
    for (let i = 0; i < this.filteredContent.length; i++) {
      const film = this.filteredContent[i];
      if (film.display === false) {
        this.addFilmToGroup(CatalogGroups.UNPUBLISH, film);
      } else if (i < 6) {
        this.addFilmToGroup(CatalogGroups.NEW, film);
      } else if (
        film.lang != null &&
        (film.lang.includes(LangCodes.BE) || film.lang.includes(LangCodes.BE_TARASK))
      ) {
        this.addFilmToGroup(CatalogGroups.BEL, film);
      } else {
        this.addFilmToGroup(CatalogGroups.REST, film);
      }
    }
  }

  addFilmToGroup(/* String */ groupId, /* FilmModel */ film) {
    if (groupId in this.filteredCategories) {
      this.filteredCategories[groupId].push(film);
    } else {
      this.filteredCategories[groupId] = [film];
    }
  }

  addFilmToCategory(/* String */ categoryId, /* FilmModel */ film) {
    if (categoryId == null || categoryId === "") {
      console.warn(`Film "${film.id}" has empty categroy`);
      return;
    }
    if (!(categoryId in this.contentByGenre)) {
      this.contentByGenre[categoryId] = [];
      this.genresList.push({ value: categoryId, label: i18n.t(categoryId) });
    }
    const category = this.contentByGenre[categoryId];
    category.push(film);
  }
}
