// TODO: Move to module
const getCurrentSearchParams = (searchParams) => {
  let result = {};
  const kind = searchParams.get("kind");
  const genre = searchParams.get("genre");
  const lang = searchParams.get("lang");
  if (kind) {
    result = { ...result, kind };
  }
  if (genre) {
    result = { ...result, genre };
  }
  if (lang) {
    result = { ...result, lang };
  }
  return result;
};
export default getCurrentSearchParams;
