import React from "react";
import { MdWarning } from "react-icons/md";

export function FormErrorView({ errors }) {
  if (errors == null || errors.length === 0) {
    return "";
  }
  const errorsList = [];
  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    errorsList.push(
      <div style={{ color: "rgb(255, 0, 0)" }}>
        <MdWarning />
        {error}
      </div>
    );
  }
  return errorsList;
}
