export class NLTokenType {
  // UNDEFINED
  static get UNDEFINED() {
    return 0;
  }

  // WHITE SPACE
  static get T_SPACE() {
    return 3;
  }

  static get T_SKIP() {
    return 6;
  }

  // NUMBER
  static get INT() {
    return 12;
  }

  static get NUM() {
    return 13;
  }

  static get TIME() {
    return 14;
  }

  static get DATE() {
    return 16;
  }

  // WORD
  static get WORD() {
    return 17;
  }

  // PUNCTUATION
  static get PUNCTUATION() {
    return 123;
  }

  static get PARENTHESIS() {
    return 124;
  }

  // EOS
  static get END_OF_PHRASE() {
    return 131;
  }

  // HTML
  // <b>, <br />
  static get T_XML_TAG() {
    return 201;
  }

  // &larr; &#8594; &#x2192;
  static get T_AMP_ESCAPE() {
    return 210;
  }

  static get EPSILON() {
    return 1;
  }

  static get EOL() {
    return 2;
  }

  static get ESCAPE() {
    return 4;
  }

  //
  static get T_TEXT() {
    return 11;
  }

  static get T_BOOL() {
    return 15;
  }

  static get T_NAME() {
    return 17;
  }

  static get T_LP() {
    return 20;
  }

  static get T_RP() {
    return 21;
  }

  static get T_ADD() {
    return 22;
  }

  static get T_SUB() {
    return 23;
  }

  static get T_MUL() {
    return 24;
  }

  static get T_DIV() {
    return 25;
  }

  static get T_POW() {
    return 26;
  }

  static get T_MOD() {
    return 27;
  }

  static get T_EQ() {
    return 30;
  }

  static get T_NEQ() {
    return 31;
  }

  static get T_LT() {
    return 32;
  }

  static get T_EQLT() {
    return 33;
  }

  static get T_GT() {
    return 34;
  }

  static get T_EQGT() {
    return 35;
  }

  static get T_AND() {
    return 36;
  }

  static get T_OR() {
    return 37;
  }

  static get T_NOT() {
    return 38;
  }

  static get LAMBDA() {
    return 40;
  }

  static get MEMBER_ACCESS() {
    return 41;
  }

  static get COMMA() {
    return 42;
  }

  static get QUESTION() {
    return 50;
  }

  static get COLON() {
    return 60;
  }

  static get T_START_TEMPLATE() {
    return 70;
  }

  static get T_END_TEMPLATE() {
    return 71;
  }

  static get T_START_CONDITION() {
    return 72;
  }

  static get T_START_MATH_EXPRESSION() {
    return 73;
  }

  static get T_START_CHOISE() {
    return 74;
  }

  static get T_START_FORMAT() {
    return 76;
  }

  static get T_END_SPECIAL() {
    return 77;
  }
}
