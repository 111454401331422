import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./FilmPage.css";
import "./EditFilmPage.css";
import { LoginPanel } from "../components/admin/LoginPanel";
import { FilmModel } from "../model/FilmModel";
import { Button, FormCheck, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { LaCmokEvents } from "../controller/LaCmokEvents";
import { MediaContentRating } from "../consts/MediaContentRating";
import { UploadImagePreview } from "../components/admin/UploadImagePreview";
import { JSONProxy } from "../service/json-mapper/JSONProxy";
import { LaInput } from "../components/controls/LaInput";
import { BETextTool } from "../service/text-language/BETextTool";
import { IMDBIDValidator } from "../components/validators/IMDBIDValidator";
import { FilmIdValidator } from "../components/validators/FilmIdValidator";
import { TextValidator } from "../components/validators/TextValidator";
import CatalogItemKind from "../consts/CatalogItemKind";
import { VideoSetDataMapper } from "../service/json-mapper/VideoSetDataMapper";
import { CatalogItemKindSwitch } from "../components/CatalogItemKindSwitch";
import { EditSeasons } from "../components/controls/EditSeasons";
import { AddEpisode } from "../components/controls/AddEpisode";
import { Season } from "../model/data/Season";
import { FormErrorView } from "../components/controls/FormErrorView";
import { JsonParser } from "../api/JsonParsers";
import { isUndefined } from "webpack-merge/dist/utils";
import { MdWarning } from "react-icons/md";

export function EditFilmPage(props) {
  // STATE
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [seasons, setSeasons] = useState([]);
  const [film, setFilm] = useState(null);
  const [kind, setKind] = useState(CatalogItemKind.FILM);
  const [formErrors, setFormErrors] = useState(null);
  const [user, setUser] = useState(null);
  const [filmId, setFilmId] = useState("");
  const [title, setFilmTitle] = useState("");
  const [imdbId, setIMDBId] = useState("");
  const [secondTitle, setSecondTitle] = useState("");
  const [description, setDescription] = useState("");
  const [year, setYear] = useState("");
  const [poster, setPoster] = useState("");
  const [generText, setGenerText] = useState("");
  const [studioText, setStudioText] = useState("");
  const [translatorsText, setTranslatorsText] = useState("");
  const [editorsText, setEditorsText] = useState("");
  const [dubbersText, setDubbersText] = useState("");
  const [notes, setNotes] = useState("");

  const [videoWidth, setVideoWidth] = useState("");
  const [videoHeight, setVideoHeight] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [rating, setRating] = useState("");
  const [display, setDisplay] = useState(false);
  const [posterBitmap, setPosterBitmap] = useState(null);
  // CONST
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tmdbAPI = props.tmdbAPI;
  const fbAPI = props.firebaseAPI;
  const controller = props.controller;
  const catalog = props.catalog;
  const userModel = props.userModel;
  const params = useParams();
  const existingFilmId = params.id;
  const filmidValidator = new FilmIdValidator(catalog, "Film Id", existingFilmId === null);
  const filmTitleValidator = new TextValidator(t("Title"));

  function updateFilmInfo(imdbId) {
    function isEmpty(value) {
      return value == null || isUndefined(value) || value === "" || value === "!EMPTY!";
    }

    function setImdbRelatedFileds(data) {
      const tmdbMovie = new JSONProxy(data);
      if (isEmpty(secondTitle)) {
        setSecondTitle(tmdbMovie.str("title"));
      }
      if (isEmpty(videoDuration)) {
        setVideoDuration(tmdbMovie.int("runtime"));
      }
      if (isEmpty(year)) {
        setYear(tmdbMovie.date("release_date").getFullYear());
      }
      if (isEmpty(generText)) {
        setGenerText(tmdbAPI.tmdbToLacmokGeners(tmdbMovie.arr("genres")).join(","));
      }
      if (isEmpty(poster)) {
        setPoster(tmdbAPI.getPosterUrl(tmdbMovie.str("poster_path")));
      }
    }

    function clearImdbRelatedFiled() {
      setSecondTitle("!EMPTY!");
      setVideoDuration("!EMPTY!");
      setYear("!EMPTY!");
      setGenerText("!EMPTY!");
      setPoster("!EMPTY!");
    }

    tmdbAPI.getMovieInfo(
      imdbId,
      (data) => {
        console.info(`Film '${imdbId}' was found in TMDB ID`);
        setImdbRelatedFileds(data);
      },
      (error) => {
        const tmdbError = new JSONProxy(error);
        const errorCode = tmdbError.int("status_code");
        if (errorCode === 34) {
          console.info(`Film '${imdbId}' was NOT found in TMDB ID, check TV Shows`);
          tmdbAPI.getTVInfo(imdbId, (data) => {
            console.info(`TV Show '${imdbId}' was found in TMDB ID`);
            setImdbRelatedFileds(data);
          });
        } else {
          clearImdbRelatedFiled();
        }
      }
    );
    setIMDBId(imdbId);
  }

  function form2film(filmModel) {
    const data = {
      kind,
      id: filmId,
      nazva: title,
      vytvorcasc: studioText,
      pakazvac: display ? "tak" : "ne",
      druhaNazva: secondTitle === "!EMPTY!" ? null : secondTitle,
      reit: rating,
      hod: year === "!EMPTY!" ? null : year,
      razmer: videoWidth + "x" + videoHeight,
      cas: videoDuration === "!EMPTY!" ? null : videoDuration,
      zanr: generText === "!EMPTY!" ? null : generText,
      imdbId,
      notes,
      apisanne: description,
      pieraklad: translatorsText,
      redahavannie: editorsText,
      ahucvannie: dubbersText,
      poster: poster === "!EMPTY!" ? null : poster,
      abnavleno: new Date(),
      updatedBy: user.email,
    };
    filmModel.setSeasons(seasons);
    const c = new VideoSetDataMapper(filmModel);
    if (kind === CatalogItemKind.FILM) {
      data.videoiframe = c.season2object("videos");
    } else {
      data.series = c.tv2object("seasons", null);
    }
    return data;
  }

  function film2form(filmModel) {
    setFilmId(filmModel.id);
    setKind(filmModel.kind);
    setFilmTitle(filmModel.title);
    setSecondTitle(filmModel.sndTitle);
    setIMDBId(filmModel.imdbId);
    setYear(filmModel.year);
    setPoster(filmModel.poster);
    if (filmModel.genre) setGenerText(filmModel.genre.join(","));
    if (filmModel.studio) setStudioText(filmModel.studio.join(","));
    if (filmModel.translators) setTranslatorsText(filmModel.translators.join(","));
    if (filmModel.editors) setEditorsText(filmModel.editors.join(","));
    if (filmModel.dubbers) setDubbersText(filmModel.dubbers.join(","));
    setSeasons(filmModel.getSeasons());
    setRating(filmModel.rating);
    setDescription(filmModel.desc);
    setDisplay(filmModel.display);
    setVideoWidth(isNaN(filmModel.videoWidth) ? "" : filmModel.videoWidth);
    setVideoHeight(isNaN(filmModel.videoHeight) ? "" : filmModel.videoHeight);
    setVideoDuration(isNaN(filmModel.duration) ? "" : filmModel.duration);
    setNotes(filmModel.note);
  }

  populate();
  // CONTROLLER
  controller.addEventHandler(LaCmokEvents.ON_GENERS_READY, () => {
    // console.info("AdminPage. onCatalogReady ");
    populate();
  });
  controller.addEventHandler(LaCmokEvents.ON_AUTH_CHANGED, () => {
    // console.info("AdminPage, onAuthStateChanged " + userModel.user);
    setUser(userModel.user);
    populate();
  });

  // FUNCTIONS
  function populate() {
    if (existingFilmId == null) {
      return;
    }
    if (catalog.isLoaded === false) {
      return;
    }
    if (userModel.user == null) {
      return;
    }
    const f = catalog.getFilmById(existingFilmId);
    if (f == null) {
      console.error("EditFilmPage: film with id '" + existingFilmId + "' not found");
      return;
    }
    if (f.id === filmId) {
      return;
    }
    if (film !== null && film.id === f.id) {
      return;
    }
    setFilm(f);
    film2form(f);
  }

  /**
   * Validate form before save it.
   * @returns {boolean}
   */
  function validateFilm(validateId = true) {
    let errors = [];
    if (validateId) {
      filmidValidator.validate(filmId);
      errors = errors.concat(filmidValidator.errors);
    }
    filmTitleValidator.validate(title);
    errors = errors.concat(filmTitleValidator.errors);
    setFormErrors(errors);
    return errors.length === 0;
  }

  function btnAddNewFilmClickHandler() {
    if (!validateFilm()) {
      return;
    }
    const f = new FilmModel(FilmModel.DEFAULT_DBID, kind);
    const filmData = form2film(f);
    try {
      if (posterBitmap) {
        fbAPI.updatePoster(filmId, kind, posterBitmap);
      }
      fbAPI.addFilm(filmData);
      const film = JsonParser.parseFilmData(filmData, kind, "unknown");
      catalog.addFilm(film);
      navigate("/film/" + filmId);
    } catch (err) {
      const errors = [err.toString()];
      setFormErrors(errors);
    }
  }

  function btnUpdateFilmClickHandler() {
    if (!validateFilm()) {
      return;
    }
    const f = catalog.getFilmById(existingFilmId);
    const filmData = form2film(f);
    try {
      if (posterBitmap) {
        fbAPI.updatePoster(f.id, f.kind, posterBitmap);
      }
      fbAPI.updateFilm(filmData, f.dbid);
      const film = JsonParser.parseFilmData(filmData, kind, f.dbid);
      catalog.updateFilm(film);
      navigate("/film/" + f.id);
    } catch (err) {
      const errors = [err.toString()];
      setFormErrors(errors);
    }
  }

  let editForm = "";
  if (user) {
    editForm = (
      <form className="add-film-form">
        <h3>{t("Add new film") + (filmId && " (" + filmId + ")")}</h3>
        <div className="row">
          <div className="col-md-4">
            <label className="form-label">{t("Poster")}</label>
            <UploadImagePreview
              posterURL={poster}
              resizeCallback={(bmd) => {
                setPosterBitmap(bmd);
              }}
            />
            <div className="col-md-4">
              {t("Publish")}
              <FormCheck
                type="switch"
                value={display}
                onChange={(e) => {
                  setDisplay(e.target.value);
                }}
              />
            </div>
            <CatalogItemKindSwitch
              value={kind}
              onChange={(val) => {
                setKind(val);
              }}
            />
            <p>
              <MdWarning /> Пры змене тыпа фільма відэа удалюцца
            </p>
            {existingFilmId ? (
              <Button
                id="btnUpdateFilm"
                className="btn-primary"
                size="lg"
                onClick={btnUpdateFilmClickHandler}
              >
                {t("Save")}
              </Button>
            ) : (
              <Button
                id="btnAddNewFilm"
                className="btn-primary"
                size="lg"
                onClick={btnAddNewFilmClickHandler}
              >
                {t("Add")}
              </Button>
            )}
            <FormErrorView errors={formErrors} />
          </div>
          <div className="col-md-8">
            <div className="row g-3">
              <LaInput
                className="col-md-6"
                value={title}
                label={t("Title")}
                validator={filmTitleValidator}
                onChange={(val) => {
                  setFilmTitle(val);
                  if (!existingFilmId) {
                    const filmid = BETextTool.webSaveId(val);
                    setFilmId(filmid || "");
                  }
                }}
              />
              <div className="col-md-6">
                <label className="form-label">{t("Rating")}</label>
                <ToggleButtonGroup
                  type="radio"
                  name="rateButtonGroup"
                  size="sm"
                  role="aria-label"
                  onClick={(e) => {
                    console.log("rating: " + e.currentTarget.value);
                    setRating(e.currentTarget.value);
                  }}
                  value={rating}
                >
                  <ToggleButton value={MediaContentRating.A0}>{MediaContentRating.A0}</ToggleButton>
                  <ToggleButton value={MediaContentRating.A6}>{MediaContentRating.A6}</ToggleButton>
                  <ToggleButton value={MediaContentRating.A12}>
                    {MediaContentRating.A12}
                  </ToggleButton>
                  <ToggleButton value={MediaContentRating.A16}>
                    {MediaContentRating.A16}
                  </ToggleButton>
                  <ToggleButton value={MediaContentRating.A18}>
                    {MediaContentRating.A18}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="col-md-12">
                <label className="form-label">{t("Description")}</label>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">{t("Notes")}</label>
                <textarea
                  className="form-control"
                  required
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </div>
            </div>
            <h3>Відэа</h3>
            <EditSeasons seasons={seasons} />
            <AddEpisode
              filmId={filmId}
              kind={kind}
              addVideo={(episode) => {
                if (seasons == null) {
                  const val = Season.CreateSeasonList(filmId, episode);
                  setSeasons(val);
                  forceUpdate();
                } else {
                  Season.AddVideo(seasons, episode);
                  forceUpdate();
                }
              }}
            />
            <h3>{t("Movie info")}</h3>
            <div className="row g-3">
              <LaInput
                className="col-md-6"
                value={imdbId}
                label="IMDB ID"
                validator={new IMDBIDValidator("MDB ID")}
                onChange={(imdbId) => {
                  updateFilmInfo(imdbId);
                }}
              />
              <LaInput
                className="col-md-6"
                label={t("Second Title")}
                highlightChanges={true}
                value={secondTitle}
                onChange={(val) => {
                  setSecondTitle(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("year")}
                highlightChanges={true}
                value={year}
                onChange={(val) => {
                  setYear(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("Duration")}
                highlightChanges={true}
                value={videoDuration}
                onChange={(val) => {
                  setVideoDuration(val);
                }}
              />
              <LaInput
                className="col-md-6"
                label={t("Gener")}
                highlightChanges={true}
                value={generText}
                onChange={(val) => {
                  setGenerText(val);
                }}
              />
              <div className="col-md-3">
                <b>IMDB ID</b> павінен маць фармат "tt12210708" Калі фільм есць у бізе дадзеных паля{" "}
                {t("Duration")},{t("Gener")},{t("year")} запоўняцца аўтаматычна.
                <br />
                <br />
                <b>{t("Duration")}</b> - даўжыня фільма у хвілінах
              </div>
              <div className="col-md-3">
                <b>{t("Gener")}</b> павінен маць спіс ID праз коску (напрыклад "act,adv,c")
                <ul>
                  <li>war - Вайна</li>
                  <li>w - Вэстэрн</li>
                  <li>r - Рамантыка</li>
                  <li>h - Жуды</li>
                  <li>f - Сямейны</li>
                  <li>c - Камэдыя</li>
                  <li>d - Драма</li>
                  <li>t - Трылер</li>
                  <li>s - Спорт</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>m - Містыка</li>
                  <li>act - Акцыя</li>
                  <li>adv - Прыгоды</li>
                  <li>anim - Анімацыя</li>
                  <li>biograpy - Біяграфія</li>
                  <li>crime - Крымінал</li>
                  <li>doc - Дакумэнтальны</li>
                  <li>fantasy - Фэнтазі</li>
                  <li>history - Гістарычны</li>
                  <li>sf - Фантастыка</li>
                  <li>short - Кароткі мэтар</li>
                  <li>music - Музычны</li>
                  <li>mult - Мультфільм</li>
                  <li>minis - Кароткі сэрыял</li>
                </ul>
              </div>
            </div>
            <h3>{t("Cast")}</h3>
            <div className="row g-3">
              <LaInput
                className="col-md-3"
                label={t("Studio")}
                value={studioText}
                onChange={(val) => {
                  setStudioText(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("Translators")}
                value={translatorsText}
                onChange={(val) => {
                  setTranslatorsText(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("Editors")}
                value={editorsText}
                onChange={(val) => {
                  setEditorsText(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("Dubbers")}
                value={dubbersText}
                onChange={(val) => {
                  setDubbersText(val);
                }}
              />
            </div>
            <h3>{t("Video Atributes")}</h3>
            <div className="row g-3">
              <LaInput
                className="col-md-3"
                label={t("VideoWidth")}
                value={videoWidth}
                onChange={(val) => {
                  setVideoWidth(val);
                }}
              />
              <LaInput
                className="col-md-3"
                label={t("VideoHeight")}
                value={videoHeight}
                onChange={(val) => {
                  setVideoHeight(val);
                }}
              />
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    editForm = "";
  }

  return (
    <>
      <LoginPanel controller={props.controller} firebaseAPI={fbAPI} user={user} />
      {editForm}
    </>
  );
}
