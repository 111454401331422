/**
 *
 */
export class UserData {
  /**
   * Full name of the user
   * @type {null}
   */
  fn = null;

  /**
   * Nickname is a reauired field to identify player. Nickname must be unique
   * but could have any UTF letters.
   *
   * @type {string}
   */
  nickname = "";
  role = "";
  title = "";

  /**
   * URL to the user avatar
   * @type {string}
   */
  photo = "";

  /**
   * Simple HTML format of personal description
   * @type {string}
   */
  note = "";

  // Support platform
  patreon = "";

  // TODO remove support of donation alerts as RUS-service.
  donationalerts = "";

  // Social
  youtube = "";
  telegram = "";
  instagram = "";
  twitter = "";
  tiktok = "";

  //
  email = "";

  hasSocialLinks() {
    const links = this.getSocialLinks();
    return links.length > 0;
  }

  getSocialLinks() {
    const result = [];
    if (this.youtube) result.push(["youtube", this.youtube]);
    if (this.telegram) result.push(["telegram", this.telegram]);
    if (this.instagram) result.push(["instagram", this.instagram]);
    if (this.twitter) result.push(["twitter", this.twitter]);
    if (this.tiktok) result.push(["tiktok", this.tiktok]);
    return result;
  }

  hasSupportLinks() {
    const links = this.getSupportLinks();
    return links;
  }

  getSupportLinks() {
    const result = [];
    if (this.patreon) result.push(["patreon", this.patreon]);
    if (this.donationalerts) result.push(["telegram", this.donationalerts]);
    return result;
  }

  toString() {
    return "{U: " + this.nickname + "}";
  }
}
