import { BaseValidator } from "./BaseValidator";

export class FilmIdValidator extends BaseValidator {
  filmCatalog = null;
  filedName = null;
  mustBeUnique = true;

  constructor(/* CaatalogModel */ filmCatalog, /* String */ fieldName, mustBeUnique) {
    super();
    this.filmCatalog = filmCatalog;
    this.filedName = fieldName;
    this.mustBeUnique = mustBeUnique;
  }

  validate(filmId) {
    super.validate(filmId);
    if (filmId === null && filmId === "") {
      this.errors.push(`Filed '${this.filedName}' must be not empty`);
      return;
    }
    if (!this.mustBeUnique) {
      return;
    }
    let film = null;
    if (this.filmCatalog) {
      film = this.filmCatalog.getFilmById(filmId);
    }
    if (film !== null) {
      this.errors.push(`Film with id='${filmId}' already exists. Please choose another film id.`);
    }
  }
}
