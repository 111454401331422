import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { RiPlayCircleFill } from "react-icons/ri";
import React, { useState } from "react";

export function CatalogPoster({ film, showTitle, fbApi }) {
  const [posterURL, setPosterURL] = useState("");
  let rating = "";
  if (film.rating) {
    rating = <p className="poster-rating">{film.rating}</p>;
  }
  const studio = [];
  if (film.studio && film.studio.length) {
    for (let i = 0; i < film.studio.length; i++) {
      studio.push(<p className="poster-studio">{film.studio[i]}</p>);
    }
  }
  const filmId = film.id;
  if (film.poster === null || film.poster === "") {
    fbApi.getPosterUrl(filmId, film.kind, (url) => {
      film.poster = url;
      setPosterURL(url);
    });
  }
  const poster = film.poster ? film.poster : "/assets/NoPoster.jpg";
  if (posterURL !== poster) setPosterURL(poster);
  return (
    <Col key={filmId} className="film-poster-col">
      <Link className="film-link" to={"/film/" + filmId}></Link>
      <div className="film-poster">
        <img src={posterURL} />
      </div>
      <div className="film-poster-overlay">
        <RiPlayCircleFill className="poster-play" />
      </div>
      <div className="film-poster-content">
        {rating} {studio}
      </div>
      {showTitle ? <div className="film-poster-title primary-text">{film.title}</div> : ""}
      {showTitle && film.sndTitle ? (
        <div className="film-poster-sub-title secondary-text">{film.sndTitle}</div>
      ) : (
        ""
      )}
    </Col>
  );
}
