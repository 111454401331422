export class GoogleDriveTools {
  /**
   * Url sample https://drive.google.com/file/d/1SLDU-2AQ8-0u_Hez68S4OSXjxOzwQOHx/preview
   * Url https://drive.google.com/file/d/1AfjKPXxzkBPEeLkAznZ7szXcmfdtA4sc/view
   * @returns {RegExp}
   * @constructor
   */
  static get GOOGLE_DRIVE_URL_PATTERN() {
    return /(https?:\/\/drive\.google\.com\/file\/d\/([0-9a-zA-Z-_]+)\/).*/;
  }

  static ValidateURL(inputStr) {
    return GoogleDriveTools.GOOGLE_DRIVE_URL_PATTERN.test(inputStr);
  }

  static GetURL(inputStr) {
    const result = GoogleDriveTools.GOOGLE_DRIVE_URL_PATTERN.exec(inputStr);
    if (result) {
      return result[1];
    }
    return null;
  }

  static GoogleShareID(inputStr) {
    const result = GoogleDriveTools.GOOGLE_DRIVE_URL_PATTERN.exec(inputStr);
    if (result) {
      return result[2];
    }
    return null;
  }
}
