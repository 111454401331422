/**
 *
 */
import { JsonParser } from "../api/JsonParsers";
import { LaCmokEvents } from "../controller/LaCmokEvents";

/**
 * List of all users used in film definitions. This list include only public
 * data about users.
 *
 */
export class UsersCatalogModel {
  isLoaded = false;
  users = [];
  usersByNickname = {};

  constructor(c) {
    this.create();
    this.controller = c;
  }

  create() {
    this.isLoaded = false;
    this.users = [];
    this.usersByNickname = {};
  }

  build(data) {
    this.create();
    for (let userIndex = 0; userIndex < data.length; userIndex++) {
      const userData = data[userIndex];
      const userDTO = JsonParser.parseUserData(userData);
      if (userDTO != null) {
        this.users.push(userDTO);
        this.usersByNickname[userDTO.nickname] = userDTO;
      }
    }
    this.ready();
  }

  ready() {
    console.info("INFO: UserModel done in total " + this.users.length + " users was detected");
    this.isLoaded = true;
    this.controller.dispatchEvent(LaCmokEvents.ON_USER_READY);
  }

  getMemberById(id) {
    let result = null;
    if (id in this.usersByNickname) {
      result = this.usersByNickname[id];
    }
    return result;
  }

  toString() {
    return "{Users: " + this.users.join(", ") + "}";
  }
}
