import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EpisodeButtons from "../components/EpisodeButtons";
import "./FilmPage.css";
import { Helmet } from "react-helmet";
import Alert from "react-bootstrap/Alert";
import { isIOS, isMobile } from "react-device-detect";
import { LaCmokEvents } from "../controller/LaCmokEvents";
import { MdPlayCircleFilled } from "react-icons/md";
import { ReportErrorButton } from "../components/shared/ReportErrorButton";
import CatalogItemKind from "../consts/CatalogItemKind";
import { PageLoader } from "../components/shared/PageLoader";

export function FilmPage(props) {
  // PARAMS
  const { t } = useTranslation();
  const filmCatalog = props.catalog;
  const controller = props.controller;
  const params = useParams();
  const filmId = params.id;
  const filmData = filmCatalog.getFilmById(filmId);
  // STATE
  const [film, setFilm] = useState(filmData);
  const [selectedEpisod, setSelectedEpisod] = useState(null);
  // CONTROLLER
  controller.addEventHandler(LaCmokEvents.ON_CATALOG_READY, () => {
    setFilm(filmCatalog.getFilmById(filmId));
  });
  console.log("INFO: FilmPage.render(" + filmId + "), selectedEpisode: " + selectedEpisod);
  let defaultEpisode = null;

  /**
   *
   * @param {string} title
   * @param {Season} season
   * @return {ReactElemnt}
   */
  function createButtonGroup(title, season /* Season */, tvShow = false) {
    if (season == null || season.episodes == null) {
      return null;
    }
    for (let i = 0; i < season.episodes.length; i++) {
      const e /* VideoData */ = season.episodes[i];
      let selected = false;
      if (selectedEpisod) {
        selected = selectedEpisod === e.url;
        const sText = selected ? "selected" : "not selected";
        console.log(sText + " episode: " + e.url);
      } else if (defaultEpisode) {
        selected = defaultEpisode === e.url;
      } else {
        defaultEpisode = e.url;
        selected = true;
      }
    }
    if (tvShow) {
      return (
        <div key={season.sid}>
          <div className="season-title">{title}:</div>
          <EpisodeButtons
            videosArray={season.episodes}
            selectedEpisod={selectedEpisod}
            onSelectEpisode={setSelectedEpisod}
          />
        </div>
      );
    } else if (season.size() > 1) {
      return (
        <div key={season.sid}>
          <EpisodeButtons
            videosArray={season.episodes}
            selectedEpisod={selectedEpisod}
            onSelectEpisode={setSelectedEpisod}
          />
        </div>
      );
    }
    return null;
  }

  function createList(list, title, titleClassName, elementClassName) {
    let result = "";
    if (list != null && list.length) {
      const elements = [];
      for (let i = 0; i < list.length; i++) {
        const uri = `/member/${list[i]}`;
        elements.push(
          <li key={titleClassName + i}>
            <Link id={titleClassName + i} className={elementClassName} to={uri}>
              {list[i]}
            </Link>
          </li>
        );
      }
      result = (
        <div>
          <h1 className={titleClassName}>{title}</h1>
          <ul>{elements}</ul>
        </div>
      );
    }
    return result;
  }

  if (filmId === null || filmId === undefined) {
    return <div className="film-container">Wrong film id</div>;
  } else if (film === null || film === undefined) {
    return <PageLoader />;
  } else {
    filmCatalog.api.logMovie(film);
    let title = film.title;
    if (film.year) {
      title += " (" + film.year + ")";
    }
    let ratingStyle = "film-rating";
    if (["18+", "16+"].includes(film.rating)) {
      ratingStyle = "film-rating-red";
    } else if (film.rating === "" || film.rating === null) {
      ratingStyle = "film-rating-none";
    }

    const seasonsElement = [];
    if (film.kind === CatalogItemKind.SERYAL) {
      for (let i = 0; i < film.seasons.length; i++) {
        const season = film.seasons[i];
        seasonsElement.push(createButtonGroup(season.title, season, true));
      }
    } else {
      const buttons = createButtonGroup(t("mova"), film.videos);
      if (buttons) {
        seasonsElement.push(buttons);
      }
    }
    const studioElements = [];
    for (const p in film.team) {
      const title = t(p);
      const members = film.team[p];
      const titleStyle = "studio-title";
      const elementStyle = "studio-element";
      studioElements.push(createList(members, title, titleStyle, elementStyle));
    }

    const videoElementUrl = selectedEpisod || defaultEpisode;
    let videoElement = null;
    if (videoElementUrl == null) {
      videoElement = <div className="film-video" />;
    } else if (isMobile && isIOS) {
      videoElement = (
        <div className="film-video">
          <Alert variant="warning">{t("ihrac-na-google-drive")}</Alert>
          <a href={defaultEpisode.replace("preview", "view")} target="_blank" rel="noreferrer">
            <MdPlayCircleFilled className="play-on-google-drive" />
          </a>
        </div>
      );
    } else {
      videoElement = (
        <iframe className="film-video" src={videoElementUrl} width="100%" allow="autoplay"></iframe>
      );
    }

    const geners = [];
    for (let generId = 0; generId < film.genre.length; generId++) {
      if (generId > 0) {
        geners.push(", ");
      }
      const gener = film.genre[generId];
      const uri = `/filter?genre=${gener}`;
      geners.push(
        <Link id={gener} to={uri}>
          {t(gener)}
        </Link>
      );
    }

    let notesElement = <div />;
    if (film.note != null && film.note !== "") {
      notesElement = (
        <div className="film-desc-block">
          <h2 className="film-second-title">{t("Film notes")}</h2>
          <div className="film-details" dangerouslySetInnerHTML={{ __html: film.note }} />
        </div>
      );
    }
    return (
      <div className="film-container">
        <Helmet>
          <title>
            {title} | {t("site-title")}
          </title>
        </Helmet>
        {videoElement}
        {seasonsElement}
        <div className="film-description-layout">
          <div className="film-description-layout-main">
            <h1 className="film-title">{title}</h1>
            <h2 className="film-second-title">
              {film.sndTitle} <span className={ratingStyle}>{film.rating}</span>
            </h2>
            <p className="film-description">
              <span className="film-quality" title={film.size}>
                {film.quality}
              </span>
              <span className="film-geners">{geners}</span>
              <br />
            </p>
            <div
              className="film-desc-block film-details"
              dangerouslySetInnerHTML={{ __html: film.desc }}
            />
            {notesElement}
          </div>
          <div className="film-description-layout-side">
            {studioElements}
            <ReportErrorButton />
          </div>
        </div>
      </div>
    );
  }
}
